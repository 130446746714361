import * as React from 'react';
import { render, hydrate } from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

import configureStore from './config/store';
import Routes from './config/routes';

const rootEl = document.getElementById('root');
const conFStore = configureStore();
export const store = conFStore.store;
export const history = conFStore.history;

const renderMethod = process.env.HYDRATE ? hydrate : render;

renderMethod(
    <AppContainer>
        <Provider store={ store }>
            <ConnectedRouter history={ history }>
                <Routes />
            </ConnectedRouter>
        </Provider>
    </AppContainer>,
    rootEl
);
