import * as React from 'react';
import {IUser} from '../../interfaces';
import { connect } from 'react-redux';
import {AUTH_LINK, LOGOUT_LINK, PASS_LINK} from '../../config/urls';
import { Redirect } from 'react-router';
import {routeCodes} from '../../config/routes';

const ls = require('store');

export interface AppProps {
    authUser: IUser;
}

class HeaderComponent extends React.Component<AppProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            opened: false,
            redirect: false,
            redirect2: false,
        };
    }
    profile() {
        this.setState({ redirect: true });
    }
    changeEmail() {
        this.setState({ redirect2: true });
    }
    restorePass() {
        window.open(PASS_LINK, '_blank');
    }
    logout() {
        const url = LOGOUT_LINK + '?token=' + ls.get('access_token') + '&redirect_uri=' + encodeURIComponent(AUTH_LINK);
        ls.remove('access_token');
        window.location.href = url;
    }
    render() {
        if (this.state.redirect) {
            return <Redirect push to={routeCodes.PROFILE} />;
        }
        if (this.state.redirect2) {
            return <Redirect push to={routeCodes.CHANGE_EMAIL} />;
        }
        return(
            <React.Fragment>
                <header className='HeaderComponent'>
                    <div className='HeaderComponent__wrapper'>
                        <a href='/' className='HeaderComponent__logo'>НОК.ОРГ</a>
                        {this.props.authUser && (
                          <div className='HeaderComponent__user-panel'>
                              <div className='filter' style={{ width: '330px'}}>
                                  <div
                                    className={ this.state.opened ? 'select select_opened' : 'select'}
                                    onClick={ (e) => {
                                        this.state.opened ? this.setState({opened: false}) : this.setState({opened: true});
                                    }}>
                                      <div style={ { backgroundImage: `url(${ this.props.authUser.profile.picture })` } }
                                          className='HeaderComponent__photo'>
                                      </div>
                                      <div className='select__name'>
                                          {this.props.authUser.profile.name + ' ' + this.props.authUser.profile.surname}
                                      </div>
                                      <div className='select__box'>
                                          <div className='select__item'>
                                              <div className='select__label'>
                                                  {this.props.authUser.type === 'federal' ? 'Федеральный аккаунт' : (this.props.authUser.regions[0] ? this.props.authUser.regions[0].region : '')}
                                              </div>
                                          </div>
                                          <div onClick={() => this.profile()} className='select__item'>
                                              <div className='select__label'>Настройки профиля</div>
                                          </div>
                                          <div onClick={() => this.changeEmail()} className='select__item'>
                                              <div className='select__label'>Изменить email</div>
                                          </div>
                                          <div onClick={() => this.restorePass()} className='select__item'>
                                              <div className='select__label'>Изменить пароль</div>
                                          </div>
                                          <div onClick={() => this.logout()} className='select__item'>
                                              <div className='select__label'>Выйти</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        )}
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

export default connect<{}, {}, any>((state: any) => {
    return {
        authUser: state.app.authUser
    };
})(HeaderComponent);
