import * as React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {ATTACH_SERVER, AUTH_LINK} from '../config/urls';
import HeaderComponent from './Landing/HeaderComponent';
import {IUser} from '../interfaces';

const qs = require('qs');
const ls = require('store');

export interface AppProps {
  authUser: IUser;
}

class Profile extends React.Component<AppProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      sid: '',
      password: '',
      password_c: '',
      agreeValue: false,
      auto_report: false,
      picture: '',
    };
  }

  componentDidMount() {
    axios.get('https://api.rating.we-change.ru/user/' + this.props.authUser.id).then((r) => {
      this.setState({
        email: r.data.email,
        name: r.data.profile.name,
        surname: r.data.profile.surname,
        patronymic: r.data.profile.patronymic,
        type: r.data.type,
        phone: r.data.profile.phone,
        password: '',
        picture: r.data.profile.picture,
        auto_report: r.data.auto_report,
        dob: new Date(r.data.profile.date_of_birth).toISOString().slice(0, 10),
        gender: r.data.profile.gender,
        post: r.data.profile.post,
        id: r.data.id
      });
    });
  }

  register() {
    axios.post('https://api.rating.we-change.ru/user/register/' + this.state.id, qs.stringify({
      gender: this.state.gender,
      post: this.state.post,
      email: this.state.email,
      name: this.state.name,
      surname: this.state.surname,
      patronymic: this.state.patronymic,
      type: this.state.type,
      phone: this.state.phone,
      picture: this.state.picture,
      auto_report: this.state.auto_report,
      date_of_birth: new Date(this.state.dob).toISOString().slice(0, 10),
    })).then(r => {
      if (r.data && r.data.text) {
        alert(r.data.text);
      }
    }).catch(e => {
      console.log(e);
    });
  }

  UploadFiles(files: FileList) {
    const data = new FormData();
    data.append('upload', files[0]);
    axios.post(ATTACH_SERVER + '/upload/', data, {
      headers: {
        'Authorization': 'Bearer ' + ls.get('access_token'),
      },
    })
      .then((res) => {
        this.setState({ picture: res.data.data.link_to_thumb });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error) {
          alert(err.response.data.error);
        }
      });
  }

  render() {
    return (
      <>
        <div className='modal'>
          <div className='modal__page'>
            <div className='modal__wrap'>
              <HeaderComponent />
              <div className='modal__form'>
                <div className='modal__card'>
                  <div className='reg__form'>
                    <h3 className='heading heading_h3'>
                      Редактирование профиля
                    </h3>
                    <div className='reg__photo-box'>
                      <div
                        style={ { backgroundImage: `url(${ this.state.picture })` } }
                        data-photo='profile'
                        className='reg__photo'
                      />
                      <div className='reg__file-change'>
                        Изменить
                      </div>
                      <input
                        type={ 'file' }
                        accept={ 'image/* ' }
                        onChange={ (e) => this.UploadFiles(e.target.files) }
                      />
                    </div>
                    <input className='reg__input' type='text' placeholder='Должность...'
                           value={this.state.post}
                           name='post'
                           onChange={(e) => this.setState({ post: e.target.value })}
                    />
                    <h4 className='heading heading_h4'>
                      Дата рождения
                    </h4>
                    <input className='reg__input' type='date' placeholder='Дата рождения...'
                           value={this.state.dob}
                           name='dob' max={(new Date().toISOString()).slice(0, 10)}
                           onChange={(e) => this.setState({ dob: e.target.value })}
                    />
                    <select value={this.state.gender} className='reg__input' onChange={(e) => this.setState({ gender: e.target.value })}>
                      <option value={0}>Укажите пол</option>
                      <option value={1}>Женский</option>
                      <option value={2}>Мужской</option>
                    </select>
                    <div className='reg__next'>
                      <label className='checkbox'>
                        <input type='checkbox' checked={this.state.auto_report}
                               onChange={(e) => this.setState({ auto_report: e.target.checked })}
                               name='auto_report'/>
                        <div className='checkbox__icon'/>
                        <div className='checkbox__label'>Я согласен(на) получать рассылку на свой e-mail</div>
                      </label>
                      <button className='button button_bold reg__button'
                              onClick={() => this.register()}
                      >СОХРАНИТЬ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect<{}, {}, any>((state: any) => {
  return {
    authUser: state.app.authUser
  };
})(Profile);
