import * as React from 'react';
import {connect} from 'react-redux';
import { setDateSelectedTitle, updateFilters} from '../../actions/app';
import {IUser} from '../../interfaces';
import axios from 'axios';
import Regions from '../../helpers/Regions';
import {RATING_URL} from '../../config/urls';

const Moment = require('moment');
const MomentRange = require('moment-range');
const ls = require('store');

let moment = MomentRange.extendMoment(Moment);
moment.locale('ru');

export interface AppProps {
    dispatch?: (action) => any;
    filters?: any;
    authUser?: IUser;
    filterVisible: number;
    time: any;
    dateSelectedTitle: string;
    updateFilters: string;
}

class FiltersComponent extends React.Component<AppProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: moment('01.01.2016', 'DD.MM.YYYY'),
            dateTo: moment(),
            dfrom: moment().subtract(2, 'week').format('YYYY-MM-DD'),
            dto: moment().format('YYYY-MM-DD'),
            tempDateFrom: moment('01.01.2016', 'DD.MM.YYYY').format('YYYY-MM-DD'),
            tempDateTo: moment().format('YYYY-MM-DD'),
            categoryId: 0,
            sphereId: 0,
            categories: [],
            spheres: [],
            filterVisible: 0,
            sphereSelectedTitle: 'Все',
            categorySelectedTitle: 'Все',
            dateSelectedName: 'all',
            dateSelectedTitle: 'За все время',
            regionSelectedTitle: ((this.props.authUser && this.props.authUser.regions && this.props.authUser.regions[0]) ? this.props.authUser.regions[0].region : 'Все'),
            regionId: ((this.props.authUser && this.props.authUser.regions && this.props.authUser.regions[0]) ? this.props.authUser.regions[0].region_fias : ''),
            filterRegion: '',
            reportModal: false,
            loadingReport: false
        };
        if (this.props.authUser && this.props.authUser.regions && this.props.authUser.regions[0]) {
          const filters = this.props.filters ? this.props.filters : {};
          filters['region'] = this.state.regionId;
          this.props.dispatch(updateFilters(filters));
        }
    }

    componentDidMount() {
        axios.get('https://api.rating.we-change.ru/category/')
            .then(r => {
                const userCategories = [];
                if (this.props.authUser.categories) {
                    this.props.authUser.categories.forEach(c => {
                        userCategories.push(c.category_id);
                    });
                }
                const categories = [];
                const userSpheres = [];
                r.data.forEach(c => {
                    if (userCategories.indexOf(c.id) !== -1) {
                        categories.push(c);
                        userSpheres.push(c.sphere_id);
                    }
                });
                this.setState({ categories }, () => {
                    axios.get('https://api.rating.we-change.ru/sphere/')
                        .then(r => {
                            const spheres = [];
                            r.data.forEach(s => {
                                if (userSpheres.indexOf(s.id) !== -1) {
                                    spheres.push(s);
                                }
                            });
                            this.setState({ spheres });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                });
            })
            .catch(e => {
                console.log(e);
            });

        this.props.dispatch(setDateSelectedTitle(this.state.dateSelectedTitle));

        let th = this;
        document.body.addEventListener('click', function(e: any) {
            if (e.path[3].className !== 'select select_opened') {
                th.setState({filterVisible: 0});
            }
        });
    }
    dateSelect(t) {
        let time = [];
        time['custom'] = ['Произвольный ввод', moment(this.state.tempDateFrom, 'YYYY-MM-DD'), moment(this.state.tempDateTo, 'YYYY-MM-DD')];
        time['all'] = ['За все время', moment('2016-01-01', 'YYYY-MM-DD'), moment()];
        time['today'] = ['Сегодня', moment().startOf('day'), moment()];
        time['yesterday'] = ['Вчера', moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        time['week'] = ['Неделя', moment().startOf('week'), moment().endOf('week')];
        time['currentMonth'] = ['Текущий месяц', moment().startOf('month'), moment().endOf('month')];
        time['lastMonth'] = ['Прошлый месяц', moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')];

        this.setState( prevState => {
            return {
                dateSelectedName: t,
                dateSelectedTitle: time[t][0],
                filterVisible: t !== 'custom' ? 0 : prevState.filterVisible,
            };
        }, () => {
            this.props.dispatch(setDateSelectedTitle(time[t][0]));
            this.dateUpdateFilters(time[t][1], time[t][2]);
        });
    }

    isValid(v) {
        return moment(v, 'YYYY-MM-DD', true).isValid();
    }

    dateChange(type, input) {
        (type === 'from' ? (
            this.setState({tempDateFrom: input}, () => {
                if (this.isValid(input)) this.dateSelect('custom');
            })
        ) : (
            this.setState({tempDateTo: input}, () => {
                if (this.isValid(input)) this.dateSelect('custom');
            })
        ));
    }

    dateUpdateFilters(from, to) {
        const filters = this.props.filters ? this.props.filters : {};

        (from && from.isValid()) && (to && to.isValid()) ? (
            this.setState({ dateFrom: from, dateTo: to }, () => {
                filters['dateFrom'] = from; // this.state.dateFrom;
                filters['dateTo'] = to; // this.state.dateTo;
                this.props.dispatch(updateFilters(filters));
            })
        ) : '';
    }

    getRegions() {
        return Regions.regions.filter((region) => {
          return region.name.toLowerCase().indexOf(this.state.filterRegion.toLowerCase()) > -1;
        });
    }

    sleep (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getReport () {
        const _this = this;
        this.setState({
            loadingReport: true
        }, function () {
            axios.get(RATING_URL + '/landing-report/?dfrom=' + new Date(this.state.dfrom).toISOString().slice(0, 10) + '&dto=' + new Date(this.state.dto).toISOString().slice(0, 10) + '&token=' + ls.get('access_token'))
              .then(function (r) {
                  if (r.data.code === 200) {
                      // @ts-ignore
                      async function f () {
                          while (true) {
                              await _this.sleep(10000);
                              let complete = false;
                              axios.get(RATING_URL + '/' + r.data.text).then(function (res) {
                                  if (res.data.code === 201) {
                                      complete = true;
                                      _this.setState({loadingReport: false});
                                      window.open(RATING_URL + '/' + res.data.text);
                                      alert('Отчёт сформирован. Убедитесь, что у вас разрешены всплывающие окна');
                                  }
                              }).catch(function (err) {
                                  complete = true;
                                  _this.setState({
                                      loadingReport: false
                                  }, function () {
                                      alert('Не удалось сформировать отчёт');
                                  });
                              });
                              await _this.sleep(1000);
                              if (complete) { break; }
                          }
                      }
                      f();
                  }
              }).catch(function (e) {
                _this.setState( {
                    loadingReport: false
                }, function () {
                    alert('Не удалось сформировать отчёт');
                });
            });
        });
    }

    render() {
        if (this.state.reportModal) {
            return (<>
                    <div className='modal' style={{ marginTop: '60px', zIndex: 100 }}>
                        <div className='modal__page'>
                            <div className='modal__wrap'>
                                <h1 className='heading heading_h1 modal__heading'>Новый отчёт</h1>
                                <div className='text text_sub modal__text'>
                                    Сформируйте отчёт за выбранный период и загрузите его
                                </div>
                                <div className='modal__form'>
                                    <div className='modal__card'>
                                        <div className='report__form'>
                                            <h3 className='heading heading_h3'>
                                                Выберите период
                                            </h3>
                                            <h4 style={{display: 'inline-block'}} className='heading heading_h4'>
                                                От&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </h4>
                                            <input className='reg__input' type='date'
                                                   value={this.state.dfrom} style={{ width: '40%' }}
                                                   name='dfrom' max={this.state.dto}
                                                   onChange={(e) => this.setState({ dfrom: e.target.value }, () => console.log(this.state.dfrom))}
                                            />
                                            <h4 style={{display: 'inline-block'}} className='heading heading_h4'>
                                                До&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </h4>
                                            <input className='reg__input' type='date'
                                                   value={this.state.dto} style={{ width: '40%' }}
                                                   name='dto' min={this.state.dfrom} max={(new Date().toISOString()).slice(0, 10)}
                                                   onChange={(e) => this.setState({ dto: e.target.value })}
                                            />
                                            {/*this.props.authUser.type === 'federal' && (
                                              <>
                                                  <h3 className='heading heading_h3'>
                                                      Настройка отчёта
                                                  </h3>

                                              </>
                                            )*/}
                                            <button className='button button_bold reg__button'
                                                    disabled={this.state.loadingReport}
                                                    onClick={() => {
                                                        if (!this.state.dfrom || !this.state.dto) {
                                                            alert('Выберите даты');
                                                            return;
                                                        }
                                                        if (new Date(this.state.dfrom).getTime() > new Date(this.state.dto).getTime()) {
                                                            alert('Дата "до" должна быть позже даты "от"');
                                                            return;
                                                        }
                                                        this.getReport();
                                                    }}
                                            >
                                                {this.state.loadingReport && (
                                                  <i style={{ marginRight: '10px' }} className='fa fa-spinner fa-spin'></i>
                                                )}
                                                { this.state.loadingReport ? 'ОЖИДАЙТЕ' : 'ЗАГРУЗИТЬ ОТЧЁТ' }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>);
        }
        return(
            <>
                <div className='FiltersComponent'>
                    <div className='filters'>
                        <div className='filters__wrap'>
                            <div className='filter'>
                                <div
                                    className={ this.state.filterVisible === 1 ? 'select select_opened' : 'select'}
                                    onClick={ (e) => {
                                        this.state.filterVisible === 0 ? this.setState({filterVisible: 1}) : this.setState({filterVisible: 0});
                                    }}>
                                    <div className='select__name'>
                                        Сфера
                                    </div>
                                    <div className='select__box'>
                                        <div className='select__item'>
                                            <div className='select__label'>{this.state.sphereSelectedTitle}</div>
                                        </div>

                                        <input type='text' className='input filter__input hide' placeholder='Напечатайте для поиска...'/>
                                        <div className='select__item' onClick={ (e) => {
                                                this.setState({sphereId: 0, sphereSelectedTitle: 'Все', categorySelectedTitle: 'Все', categoryId: 0}, () => {
                                                    const filters = this.props.filters ? this.props.filters : {};
                                                    filters['sphere'] = this.state.sphereId;
                                                    filters['category'] = 0;
                                                    this.props.dispatch(updateFilters(filters));
                                                });
                                            }}>
                                            <div className='select__label'>Все</div>
                                        </div>
                                        {this.state.spheres.map(s => (
                                            <div className='select__item' onClick={ (e) => {
                                                this.setState({sphereId: s.id, sphereSelectedTitle: s.title, categoryId: 0}, () => {
                                                    const filters = this.props.filters ? this.props.filters : {};
                                                    filters['sphere'] = this.state.sphereId;
                                                    filters['category'] = 0;
                                                    this.props.dispatch(updateFilters(filters));
                                                });
                                            }}>
                                                <div className='select__label'>{s.title}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className={ this.state.sphereId === 0 ? 'filter filter_hidden' : 'filter'}>
                                <div
                                    className={ this.state.filterVisible === 2 ? 'select select_opened' : 'select'}
                                    onClick={ (e) => {
                                        this.state.filterVisible === 0 ? this.setState({filterVisible: 2}) : this.setState({filterVisible: 0});
                                    }}>
                                    <div className='select__name'>
                                        Категория
                                    </div>
                                    <div className='select__box'>
                                        <div className='select__item'>
                                            <div className='select__label'>{this.state.categorySelectedTitle}</div>
                                        </div>
                                        <input type='text' className='input filter__input hide' placeholder='Напечатайте для поиска...'/>
                                        <div className='select__item'
                                        onClick={ (e) => {
                                            this.setState({sphereId: this.state.sphereId, categorySelectedTitle: 'Все', categoryId: 0}, () => {
                                                const filters = this.props.filters ? this.props.filters : {};
                                                filters['sphere'] = this.state.sphereId;
                                                filters['category'] = 0;
                                                this.props.dispatch(updateFilters(filters));
                                            });
                                        }}>
                                            <div className='select__label'>Все</div>
                                        </div>
                                        {this.state.categories.map(c => (<React.Fragment>
                                            {Number(c.sphere_id) === Number(this.state.sphereId) && (
                                                <div className='select__item' onClick={ (e) => {
                                                    this.setState({sphereId: this.state.sphereId, categorySelectedTitle: c.title, categoryId: c.id}, () => {
                                                        const filters = this.props.filters ? this.props.filters : {};
                                                        filters['sphere'] = this.state.sphereId;
                                                        filters['category'] = c.id;
                                                        this.props.dispatch(updateFilters(filters));
                                                    });
                                                }}>
                                                    <div className='select__label'>{c.title}</div>
                                                </div>
                                            )}
                                        </React.Fragment>))}
                                    </div>
                                </div>
                            </div>
                            <div className='filter' style={{ width: '229px'}}>
                                <div className={ this.state.filterVisible === 3 ? 'select select_opened' : 'select'}>
                                    <div className='select__name'>
                                        Показать статистику
                                    </div>
                                    <div className='select__box'>
                                        <div
                                            className='select__item'
                                            onClick={ (e) => {
                                                this.state.filterVisible === 0 ? this.setState({filterVisible: 3}) : this.setState({filterVisible: 0});
                                            }}>
                                            <div className='select__label'>{this.state.dateSelectedTitle}</div>
                                        </div>
                                        <div className='select__item select__item_range'>
                                            <div className='select__label'>От</div>
                                            <input
                                                type='text'
                                                className={moment(this.state.tempDateFrom, 'YYYY-MM-DD', true).isValid() ? 'input filter__input' : 'input filter__input filter__input_error'}
                                                value={this.state.tempDateFrom}
                                                onChange={ (e) => {
                                                    this.dateChange('from', e.target.value);
                                                }}/>
                                        </div>
                                        <div className='select__item select__item_range'>
                                            <div className='select__label'>До</div>
                                            <input
                                                type='text'
                                                className={moment(this.state.tempDateTo, 'YYYY-MM-DD', true).isValid() ? 'input filter__input' : 'input filter__input filter__input_error'}
                                                value={this.state.tempDateTo}
                                                onChange={ (e) => {
                                                    this.dateChange('to', e.target.value);
                                                }}/>
                                        </div>
                                        <div className={ this.state.dateSelectedName === 'all' ? 'select__item select__item_active' : 'select__item'} onClick={ (e) => { this.dateSelect('all'); }}>
                                            <div className='select__label'>За все время</div>
                                        </div>
                                        <div className={ this.state.dateSelectedName === 'today' ? 'select__item select__item_active' : 'select__item'} onClick={ (e) => { this.dateSelect('today'); }}>
                                            <div className='select__label'>Сегодня</div>
                                        </div>
                                        <div className={ this.state.dateSelectedName === 'yesterday' ? 'select__item select__item_active' : 'select__item'} onClick={ (e) => { this.dateSelect('yesterday'); }}>
                                            <div className='select__label'>Вчера</div>
                                        </div>
                                        <div className={ this.state.dateSelectedName === 'week' ? 'select__item select__item_active' : 'select__item'} onClick={ (e) => { this.dateSelect('week'); }}>
                                            <div className='select__label'>Неделя</div>
                                        </div>
                                        <div className={ this.state.dateSelectedName === 'currentMonth' ? 'select__item select__item_active' : 'select__item'} onClick={ (e) => { this.dateSelect('currentMonth'); }}>
                                            <div className='select__label'>Текущий месяц</div>
                                        </div>
                                        <div className={ this.state.dateSelectedName === 'lastMonth' ? 'select__item select__item_active' : 'select__item'} onClick={ (e) => { this.dateSelect('lastMonth'); }}>
                                            <div className='select__label'>Прошлый месяц</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='filter' style={{ width: '210px' }}>
                                <div
                                    className={ this.state.filterVisible === 4 ? 'select select_opened' : 'select'}
                                    onClick={ (e) => {
                                        if (this.props.authUser && this.props.authUser.regions && this.props.authUser.regions[0]) {
                                            return;
                                        }
                                        this.state.filterVisible === 0 ? this.setState({filterVisible: 4}) : this.setState({filterVisible: 0});
                                    }}>
                                    <div className='select__name'>
                                        Регион
                                    </div>
                                    <div className='select__box'>
                                        <div className='select__item'>
                                            <div className='select__label'>{this.state.regionSelectedTitle}</div>
                                        </div>

                                        <input type='text' className='input filter__input hide' placeholder='Напечатайте для поиска...'/>
                                        <div className='select__item' onClick={ (e) => {
                                            this.setState({regionId: '', regionSelectedTitle: 'Все'}, () => {
                                                const filters = this.props.filters ? this.props.filters : {};
                                                filters['region'] = this.state.regionId;
                                                this.props.dispatch(updateFilters(filters));
                                            });
                                        }}>
                                            <div className='select__label'>Все</div>
                                        </div>
                                        <div className={'select-item'} onClick={(e) => {e.preventDefault(); e.stopPropagation(); }}>
                                          <input onChange={(e) => this.setState({filterRegion: e.target.value})} type='text' placeholder={'Найти'} className='input filter__input' />
                                        </div>
                                      {this.getRegions().map(region => (
                                        <div className='select__item' onClick={ (e) => {
                                          this.setState({filterVisible: 0, regionId: region.guid, regionSelectedTitle: region.name}, () => {
                                            const filters = this.props.filters ? this.props.filters : {};
                                            filters['region'] = this.state.regionId;
                                            this.props.dispatch(updateFilters(filters));
                                          });
                                        }}>
                                          <div className='select__label'>{region.name}</div>
                                        </div>
                                      ))}

                                    </div>
                                </div>
                            </div>
                            <div className='filter' style={{ width: '130px', marginTop: '10px', float: 'right' }}>
                                {this.props.authUser.type && (
                                  <button onClick={() => this.setState({reportModal: true})} className='report__button'>Получить отчёт</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default connect<{}, {}, any>((state: any) => {
    return {
        filters: state.app.filters,
        updateFilters: state.app.updateFilters,
        authUser: state.app.authUser,
        dateSelectedTitle: state.app.dateSelectedTitle,
    };
})(FiltersComponent);
