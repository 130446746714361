/**
 * DEVELOPMENT
 * * @type {string}
 */
let SITE_URL = 'https://sc.test.we-change.ru';
let API_URL = 'https://community.test.we-change.ru';
let QUERY_URL = 'https://query.test.we-change.ru';
let CHAT_SERVICE_URL = 'https://chat.test.we-change.ru';
let ATTACH_SERVER = 'https://attach.test.we-change.ru';
let WEBSOCKET_URL = 'wss://ws.test.we-change.ru';
let RATING_URL = 'https://api.rating.we-change.ru';
let USER_SERVICE_URL = 'https://user.test.we-change.ru';
let AUTH_LINK = USER_SERVICE_URL + '/authorize?response_type=token&client_id=6&state=8jvdXapia5EBb&scope=everything&redirect_uri=http://localhost:8080/';
let REG_LINK = USER_SERVICE_URL + '/register?response_type=token&client_id=6&state=8jvdXapia5EBb&scope=everything&redirect_uri=http://localhost:8080/search';
let PASS_LINK = USER_SERVICE_URL + '/restore?response_type=token&client_id=6&state=8jvdXapia5EBb&scope=everything&redirect_uri=http://localhost:8080/';
let LOGOUT_LINK = USER_SERVICE_URL + '/logout';
let configSurvey = {
  UploadUrl: 'https://attach.test.we-change.ru/',
  ApiServer: 'https://api.sc-survey.test.we-change.ru/',
  SiteUrl: '',
  AdminUrl: '',
  QuizUrl: '',
  apiServer: 'https://api.sc-survey.test.we-change.ru/',
};
/**
 * PRODUCTION
 */
if (process.env.NODE_ENV === 'production') {
  SITE_URL = 'https://sc.test.we-change.ru';
  API_URL = 'https://community.test.we-change.ru';
  QUERY_URL = 'https://query.test.we-change.ru';
  CHAT_SERVICE_URL = 'https://chat.test.we-change.ru';
  ATTACH_SERVER = 'https://attach.test.we-change.ru';
  WEBSOCKET_URL = 'wss://ws.test.we-change.ru';
    RATING_URL = 'https://api.rating.we-change.ru';
  USER_SERVICE_URL = 'https://user.test.we-change.ru';
  AUTH_LINK = USER_SERVICE_URL + '/authorize?response_type=token&client_id=8&state=cCF9DCIY5ahiUT&scope=everything&redirect_uri=https://results.test.we-change.ru/';
  PASS_LINK = USER_SERVICE_URL + '/restore?response_type=token&client_id=8&state=cCF9DCIY5ahiUT&scope=everything&redirect_uri=https://results.test.we-change.ru/';
  REG_LINK = USER_SERVICE_URL + '/register?response_type=token&client_id=8&state=cCF9DCIY5ahiUT&scope=everything&redirect_uri=https://results.test.we-change.ru/';
  LOGOUT_LINK = USER_SERVICE_URL + '/logout';
  configSurvey = {
    UploadUrl: 'https://attach.test.we-change.ru/',
    ApiServer: 'https://api.sc-survey.test.we-change.ru/',
    SiteUrl: '',
    AdminUrl: '',
    QuizUrl: '',
    apiServer: 'https://api.sc-survey.test.we-change.ru/',
  };
} else if (process.env.NODE_ENV === 'preproduction') {
  /**
   * PREPRODUCTION
   * * @type {string}
   */
  SITE_URL = 'https://sc.test.we-change.ru';
  API_URL = 'https://community.test.we-change.ru';
  CHAT_SERVICE_URL = 'https://chat.test.we-change.ru';
  QUERY_URL = 'https://query.test.we-change.ru';
  ATTACH_SERVER = 'https://attach.test.we-change.ru';
  WEBSOCKET_URL = 'wss://ws.test.we-change.ru';
    RATING_URL = 'https://api.rating.we-change.ru';
  USER_SERVICE_URL = 'https://user.test.we-change.ru';
  AUTH_LINK = USER_SERVICE_URL + '/authorize?response_type=token&client_id=6&state=8jvdXapia5EBb&scope=everything&redirect_uri=https://results.test.we-change.ru/';
  PASS_LINK = USER_SERVICE_URL + '/restore?response_type=token&client_id=6&state=8jvdXapia5EBb&scope=everything&redirect_uri=https://results.test.we-change.ru/';
  REG_LINK = USER_SERVICE_URL + '/register?response_type=token&client_id=6&state=8jvdXapia5EBb&scope=everything&redirect_uri=https://results.test.we-change.ru/';
  LOGOUT_LINK = USER_SERVICE_URL + '/logout';
  configSurvey = {
    UploadUrl: 'https://attach.test.we-change.ru/',
    ApiServer: 'https://api.sc-survey.test.we-change.ru/',
    SiteUrl: '',
    AdminUrl: '',
    QuizUrl: '',
    apiServer: 'https://api.sc-survey.test.we-change.ru/',
  };
}

export {
  API_URL,
  CHAT_SERVICE_URL,
  ATTACH_SERVER,
  AUTH_LINK,
  REG_LINK,
  WEBSOCKET_URL,
  LOGOUT_LINK,
    RATING_URL,
  QUERY_URL,
  USER_SERVICE_URL,
  SITE_URL,
  configSurvey,
  PASS_LINK,
};

