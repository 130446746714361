export default class Helper {
  public dispatch: any;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  static getQueryVariable(variable) {
    const queryHash = window.location.hash.substring(1);
    const querySearch = window.location.search.substring(1);
    const query = queryHash + '&' + querySearch;
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return (false);
  }

  static generateHash() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
}
