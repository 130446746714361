import * as React from 'react';
import {connect} from 'react-redux';
import {setSearchString} from '../../actions/app';
import { Link } from 'react-router-dom';
import {routeCodes} from '../../config/routes';
export interface AppProps {
  dispatch?: (action) => any;
  searchString?: string;
}

class TopSearchComponent extends React.Component<AppProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      query: ''
    };
  }

  render() {
    return(
      <React.Fragment>
        <div className='TopSearchComponent'>
          <div className='TopSearchComponent__search'>
            <div className='TopSearchComponent__box'>
              <input
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.props.dispatch(setSearchString(this.state.query));
                  }
                }}
                type='text'
                className='TopSearchComponent__input'
                placeholder='Введите название организации или адрес...'
                onChange={ (e) => { this.setState({ query: e.target.value });
                }}
              />
              <button className='TopSearchComponent__button' onClick={ () => { this.props.dispatch(setSearchString(this.state.query)); } }>НАЙТИ</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default connect<{}, {}, any>((state: any) => {
  return {
    searchString: state.app.searchString,
  };
})(TopSearchComponent);
