import {
    LOADING_APP, SET_AUTH_USER, SET_SEARCH_STRING, SET_DATE_SELECTED_TITLE, UPDATE_FILTERS
} from '../actions/app';
import { IUser } from '../interfaces';
import Helper from "../helpers/Helper";

interface IAppAction {
    loadingApp: boolean;
    authUser: IUser;
    searchString: string;
    dateSelectedTitle: string;
    filters: any;
    updateFilters: '';
}

const initialState: IAppAction = {
  loadingApp: true,
  authUser: null,
  searchString: '',
  dateSelectedTitle: '',
  filters: {
      city_fias: '',
      sphere: '',
      category: '',
      dateFrom: '',
      dateTo: '',
      region: '',
  },
  updateFilters: '',
};


const actionsMap = {
    [LOADING_APP]: (state, action) => {
      return { ...state, loadingApp: action.payload };
    },
    [SET_AUTH_USER]: (state, action) => {
        return { ...state, authUser: action.payload };
    },
    [SET_SEARCH_STRING]: (state, action) => {
      return { ...state, searchString: action.payload };
    },
    [SET_DATE_SELECTED_TITLE]: (state, action) => {
        return { ...state, dateSelectedTitle: action.payload };
    },
    [UPDATE_FILTERS]: (state, action) => {
        return { ...state, filters: action.payload, updateFilters: Helper.generateHash() };
    },
};

export default function reducer(state: IAppAction = initialState, action: any = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
