import * as React from 'react';
import axios from 'axios';
import {IUser} from '../../interfaces';
import { connect } from 'react-redux';

const faceImg = [];
faceImg[1] = require('../../assets/img/face-1@2x.png');
faceImg[2] = require('../../assets/img/face-2@2x.png');
faceImg[3] = require('../../assets/img/face-3@2x.png');
faceImg[4] = require('../../assets/img/face-4@2x.png');
faceImg[5] = require('../../assets/img/face-5@2x.png');

const Moment = require('moment');
const MomentRange = require('moment-range');
const ls = require('store');

let moment = MomentRange.extendMoment(Moment);
moment.locale('ru');

export interface AppProps {
    authUser: IUser;
    filters?: any;
    updateFilters: string
}

class LastCommentsComponent extends React.Component<AppProps, any> {
    constructor(props) {
        super(props);
        this.state = {
          limit: 10,
          comments: [],
          filterRating: 0
        };
    }
    componentDidMount() {
        this.getLastComments(0);
    }
    componentWillReceiveProps() {
        this.getLastComments(0);
    }
    getLastComments(filter) {
        this.setState({
            filterRating: filter || 0
        });

        let filterCategories = this.props.authUser.categories.length > 0 ? [] : [199, 210, 209, 216, 219, 204, 214, 213, 235, 245, 246, 247, 249, 250, 251, 252, 253, 255, 256, 257, 258, 259, 260], // TODO фильтры
            filterSpheres = [],
            filterFias = (this.props.filters && this.props.filters.region !== '') ? [this.props.filters.region] : [],
            filterRating = filter || 0,
            filterDateFrom = (this.props.filters && this.props.filters.dateFrom) ? this.props.filters.dateFrom : moment('01.01.2016', 'DD.MM.YYYY'),
            filterDateTo = (this.props.filters && this.props.filters.dateTo) ? this.props.filters.dateTo : moment();
        if (this.props.filters && Number(this.props.filters.sphere) > 0) {
            filterSpheres.push(Number(this.props.filters.sphere));
        }
        if (filterSpheres.length > 0) {
            filterCategories = [];
        }
        if (this.props.filters && Number(this.props.filters.category) > 0) {
            filterCategories.push(Number(this.props.filters.category));
        }
        let url = 'https://api.rating.we-change.ru/comments/?approve=1&limit=' + this.state.limit;
        url += '&dfrom=' + filterDateFrom.format('YYYY-MM-DD') + '&dto=' + filterDateTo.format('YYYY-MM-DD');
        url += '&rating=' + filterRating;
        filterCategories.forEach(fc => {
            url += '&category[]=' + fc.toString();
        });
        filterSpheres.forEach(s => {
            url += '&sphere[]=' + s.toString();
        });
        filterFias.forEach(f => {
            url += '&fias[]=' + f;
        });
        axios.get(url, {
            headers: { 'Authorization': 'Bearer ' + ls.get('access_token') }
        })
            .then(r => {
                this.setState({ comments: r.data.data });
            })
            .catch(e => {
                console.log(e);
            });
    }
    render() {
        const rateStatuses = ['', 'Очень плохо', 'Плохо', 'Нормально', 'Хорошо', 'Отлично'];
        return(
            <React.Fragment>
                <div className="LastCommentsComponent">
                    <div className="wrapper LastCommentsComponent__wrapper">
                        <div className="LastCommentsComponent__cell">
                            <h2 className="LastCommentsComponent__h2">Последние оценки</h2>
                            <div className="rating-list rating-list_recent-ratings LastCommentsComponent__rating-list">
                                {this.state.comments.map((c, idx) => (
                                    <div key={'comment-' + idx} className="card rating-list__card">
                                        <div className="info rating-list__info">
                                            <div className="info__left">
                                                <div className="info__name">
                                                    {c.name}
                                                </div>
                                                <div className="text text_bold">
                                                    Оценка № {c.id}
                                                </div>
                                            </div>
                                            <div className="info__right">
                                                <div className="info__date">
                                                    {moment(c.date).format('LLL')}
                                                </div>
                                                <div className="evaluation">
                                                    <img className="evaluation__img" src={faceImg[Math.round(c.value)]}/>
                                                    {rateStatuses[Math.round(c.value)]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="comment">
                                            {c.feedback}
                                            <div className="company-name">
                                            {c.organization.title}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        { /*<div className="LastCommentsComponent__cell">
                            <aside className="aside LastCommentsComponent__aside">
                                <div className="categories LastCommentsComponent__categories">
                                    <div className = {this.state.filterRating == 0 ? 'categories__item categories__item_active':'categories__item' } onClick={ () => { this.getLastComments(0); }}>
                                        Все оценки
                                    </div>
                                    <div className = {this.state.filterRating == 5 ? 'categories__item categories__item_active':'categories__item' } onClick={ () => { this.getLastComments(5); }}>
                                        <img className="categories__img" src={faceImg[5]}/>
                                        <div className="categories__label">Крайне положительные</div>
                                    </div>
                                    <div className = {this.state.filterRating == 4 ? 'categories__item categories__item_active':'categories__item' } onClick={ () => { this.getLastComments(4); }}>
                                        <img className="categories__img" src={faceImg[4]}/>
                                        <div className="categories__label">В основном положительные</div>
                                    </div>
                                    <div className = {this.state.filterRating == 3 ? 'categories__item categories__item_active':'categories__item' } onClick={ () => { this.getLastComments(3); }}>
                                        <img className="categories__img" src={faceImg[3]}/>
                                        <div className="categories__label">Смешанные</div>
                                    </div>
                                    <div className = {this.state.filterRating == 2 ? 'categories__item categories__item_active':'categories__item' } onClick={ () => { this.getLastComments(2); }}>
                                        <img className="categories__img" src={faceImg[2]}/>
                                        <div className="categories__label">В основном отрицательные</div>
                                    </div>
                                    <div className = {this.state.filterRating == 1 ? 'categories__item categories__item_active':'categories__item' } onClick={ () => { this.getLastComments(1); }}>
                                        <img className="categories__img" src={faceImg[1]}/>
                                        <div className="categories__label">Отрицательные</div>
                                    </div>
                                </div>
                            </aside>
                        </div> */ }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect<{}, {}, any>((state: any) => {
    return {
        authUser: state.app.authUser,
        loadingApp: state.app.loadingApp,
        filters: state.app.filters,
        updateFilters: state.app.updateFilters,
    };
})(LastCommentsComponent);
