import * as React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import './../assets/scss/App.scss';

import Login from './Login';
import { IUser } from '../interfaces';
import Helper from '../helpers/Helper';
import { LOGOUT_LINK, AUTH_LINK, RATING_URL } from '../config/urls';
import { loadingApp, setAuthUser } from '../actions/app';
import StartLoading from './StartLoading';

import HeaderComponent from './Landing/HeaderComponent';
import TopSearchComponent from './Landing/TopSearchComponent';
import TopMapComponent from './Landing/TopMapComponent';
import LastCommentsComponent from './Landing/LastCommentsComponent';
import BestWorseOrganizationsComponent from './Landing/BsetWorseOrganizationsComponent';
import FiltersComponent from './Landing/FiltersComponent';
import OrganizationsComponent from './Landing/OrganizationsComponent';
import NoPermissions from './NoPermissions';
import TopStatComponent from './Landing/TopStatComponent';

const bg = require('../assets/img/backgroundCity.svg');

const ls = require('store');
const jwt_decode = require('jwt-decode');


export interface AppProps {
    dispatch: (action) => any;
    authUser: IUser;
    loadingApp: Boolean;
}

class App extends React.Component<AppProps, undefined> {

    constructor(props: AppProps) {
        super(props);
    }

    componentDidMount() {
        const token = Helper.getQueryVariable('access_token');
        if (token) {
            ls.set('access_token', token);
        }
        if (ls.get('access_token')) {
            axios.get(RATING_URL + '/user/login-sc', {
                headers: {
                    'Authorization': 'Bearer ' + ls.get('access_token'),
                },
            }).then((r) => {
                if (r.status === 200) {
                    if (r.data && r.data.code && r.data.code === 401) {
                        const url = LOGOUT_LINK + '?token=' +
                            ls.get('access_token') + '&redirect_uri=' + encodeURIComponent(AUTH_LINK);
                        ls.remove('access_token');
                        window.location.href = url;
                    }
                    this.props.dispatch(setAuthUser(r.data));
                    this.props.dispatch(loadingApp(false));
                } else {
                    console.log(r);
                }
            }).catch((e) => {
                if (e.response.status === 401) {
                    const url = LOGOUT_LINK + '?token=' +
                        ls.get('access_token') + '&redirect_uri=' + encodeURIComponent(AUTH_LINK);
                    ls.remove('access_token');
                    window.location.href = url;
                } else {
                    console.log(e.response);
                }
                this.props.dispatch(loadingApp(false));
            });
        } else {
            this.props.dispatch(loadingApp(false));
        }
    }

    render() {

        const aUser = this.props.authUser;

        if (this.props.loadingApp) {
            return <StartLoading />;
        }

        if (!aUser) {
            return <Login />;
        }

        if ((!aUser.categories || aUser.categories.length <= 0) &&
            (!aUser.organizations || aUser.organizations.length <= 0) &&
            (!aUser.regions || aUser.regions.length <= 0)) {
            return <NoPermissions />;
        }
        return (
            <div className='app'>
                <HeaderComponent />
                <TopSearchComponent />
                <FiltersComponent />
                <TopMapComponent  authUser={aUser}/>
                <OrganizationsComponent />
                <LastCommentsComponent  authUser={aUser}/>
                <BestWorseOrganizationsComponent  authUser={aUser}/>
            </div>
        );
    }
}

export default connect<{}, {}, any>((state: any) => {
    return {
        authUser: state.app.authUser,
        loadingApp: state.app.loadingApp,
    };
})(App);

