import * as React from 'react';
import { connect } from 'react-redux';

export interface AppProps {
}

class NoPermissions extends React.Component<AppProps, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        return(
            <div>
                У Вас недостаточно прав.
            </div>
        );
    }

}

export default connect<{}, {}, any>((state: any) => {
    return state;
})(NoPermissions);
