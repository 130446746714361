import * as React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import HeaderComponent from './Landing/HeaderComponent';
import {IUser} from '../interfaces';
import {routeCodes} from '../config/routes';
import { Redirect } from 'react-router';

const qs = require('qs');
const ls = require('store');

export interface AppProps {
  authUser: IUser;
}

class ChangeEmail extends React.Component<AppProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      code: '',
      confirm: false,
      redirect: false
    };
  }

  componentDidMount() {

  }

  request() {
    axios.post('https://api.rating.we-change.ru/user/change-email', qs.stringify({
      email: this.state.email,
    }), { headers: {
      Authorization: 'Bearer ' + ls.get('access_token')
    }}).then(r => {
      if (r.data && r.data.code && r.data.code === 200) {
        this.setState({ confirm: true });
      } else if (r.data && r.data.text) {
        alert(r.data.text);
      }
    }).catch(e => {
      console.log(e);
    });
  }

  change() {
    axios.post('https://api.rating.we-change.ru/user/change-email', qs.stringify({
      code: this.state.code,
    }), { headers: {
        Authorization: 'Bearer ' + ls.get('access_token')
    }}).then(r => {
      if (r.data && r.data.code && r.data.code === 200) {
        alert('Email изменён');
        this.setState({ redirect: true });
      } else if (r.data && r.data.text) {
        alert(r.data.text);
      }
    }).catch(e => {
      console.log(e);
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={routeCodes.DASHBOARD} />;
    }
    return (
      <>
        <div className='modal'>
          <div className='modal__page'>
            <div className='modal__wrap'>
              <HeaderComponent />
              <div className='modal__form'>
                <div className='modal__card'>
                  <div className='reg__form'>
                    {this.state.confirm ? (
                      <>
                        <h3 className='heading heading_h3'>
                          Подтверждение E-Mail
                        </h3>
                        <h4 className='heading heading_h4'>
                          Введите код из письма для подтверждения нового E-Mail
                        </h4>
                        <input className='reg__input' type='text' placeholder='Код...'
                               value={this.state.code}
                               name='code'
                               onChange={(e) => this.setState({ code: e.target.value })}
                        />
                        <button className='button button_bold reg__button'
                                onClick={() => this.change()}
                        >ПОДТВЕРДИТЬ
                        </button>
                      </>
                    ) : (
                      <>
                        <h3 className='heading heading_h3'>
                          Изменение E-Mail
                        </h3>
                        <h4 className='heading heading_h4'>
                          Введите новый email и дождитесь письма с кодом для подтверждения.
                        </h4>
                        <input className='reg__input' type='text' placeholder='Новый email...'
                               value={this.state.email}
                               name='email'
                               onChange={(e) => this.setState({ email: e.target.value })}
                        />
                        <button className='button button_bold reg__button'
                                onClick={() => this.request()}
                        >ИЗМЕНИТЬ
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect<{}, {}, any>((state: any) => {
  return {
    authUser: state.app.authUser
  };
})(ChangeEmail);
