import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from '../components/App';
import OrganizationsComponent from '../components/Landing/OrganizationsComponent';
import OrgPageComponent from '../components/Landing/OrgPageComponent';
import Register from '../components/Register';
import Profile from '../components/Profile';
import ChangeEmail from '../components/ChangeEmail';

export const publicPath = '/';

export const routeCodes = {
    DASHBOARD: publicPath,
    REGISTER: `${publicPath}register`,
    CHANGE_EMAIL: `${publicPath}chang-email`,
    PROFILE: `${publicPath}profile`,
    ORGANIZATIONS: `${publicPath}organizations`,
    ORGANIZATION: `${publicPath}organizations/:id`
};


export default () => (
    <React.Fragment>
        <Switch>
            <Route exact path={ routeCodes.DASHBOARD } component={ App } />
            <Route exact path={ routeCodes.REGISTER } component={ Register } />
            <Route exact path={ routeCodes.PROFILE } component={ Profile }/>
            <Route exact path={ routeCodes.CHANGE_EMAIL } component={ ChangeEmail }/>
            <Route exact path={ routeCodes.ORGANIZATIONS } component={ OrganizationsComponent }/>
            <Route exact path={ routeCodes.ORGANIZATION } component={ OrgPageComponent }/>
        </Switch>
    </React.Fragment>
);

