import * as React from 'react';

const Moment = require('moment');
const MomentRange = require('moment-range');

let moment = MomentRange.extendMoment(Moment);
moment.locale('ru');

export interface Props {
  question: any;
  option: any;
  answers: any[];
}

class Answer extends React.Component<Props, any> {

    constructor(props) {
        super(props);
        this.state = {
            qu: 0,
            au: 0,
        };
    }

    componentWillReceiveProps() {
        let qu = 0,
            au = 0,
            answers = this.props.answers;

        answers.map((answer, i) => {
          if (answer.answer && answer.answer[this.props.question.guid]) {
              qu++;
              for (let key in answer.answer[this.props.question.guid]) {
                if (this.props.question.type === 'range4') {
                  if (this.props.option.code === key) {
                    au++;
                  }
                } else {
                  if (this.props.option.guid === key) {
                    au++;
                  }
                }
              }
            }
        });
        this.setState({
            qu: qu,
            au: au,
        });
    }

    render() {
        return (<div className='response'>
            <div className='response__text'>
                {(this.props.option.title !== '') ? this.props.option.title : this.props.option.code}
            </div>
            <div className='response__percent'>
                {this.state.qu > 0 ? (Math.round((this.state.au / this.state.qu * 100) * 100) / 100).toFixed(2) : (0).toFixed(2)}%
                | {this.state.au}
            </div>
            <div className='response__progress'>
                <div className='response__line'
                     style={{width: (this.state.qu > 0 ? (Math.round((this.state.au / this.state.qu * 100) * 100) / 100).toFixed(2) : 0) + '%'}}/>
            </div>
        </div>);
    }
}

export default Answer;
