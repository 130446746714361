export const LOADING_APP = 'LOADING_APP';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const SET_DATE_SELECTED_TITLE = 'SET_DATE_SELECTED_TITLE';


export function loadingApp(bool: boolean) {
  return {
      type: LOADING_APP,
      payload: bool,
  };
}

export function setAuthUser(user) {
  return {
      type: SET_AUTH_USER,
      payload: user,
  };
}

export function setSearchString(str: string) {
  return {
      type: SET_SEARCH_STRING,
      payload: str,
  };
}

export function setDateSelectedTitle(str: string) {
    return {
        type: SET_DATE_SELECTED_TITLE,
        payload: str,
    };    
}
export function updateFilters(filters) {
    return {
        type: UPDATE_FILTERS,
        payload: filters,
    };
}
