import * as React from 'react';
import axios from 'axios';
import {IUser} from '../../interfaces';
import { connect } from 'react-redux';
import {routeCodes} from '../../config/routes';
import {Link} from 'react-router-dom';
import {setAuthUser} from '../../actions/app';
import Rodal from 'rodal';

import TopStatComponent from '../Landing/TopStatComponent';

const Moment = require('moment');
const MomentRange = require('moment-range');
const qs = require('qs');

const faceImg = [];
faceImg[1] = require('../../assets/img/face-1@2x.png');
faceImg[2] = require('../../assets/img/face-2@2x.png');
faceImg[3] = require('../../assets/img/face-3@2x.png');
faceImg[4] = require('../../assets/img/face-4@2x.png');
faceImg[5] = require('../../assets/img/face-5@2x.png');

const iconStar = require('../../assets/img/icon-star.svg');

let moment = MomentRange.extendMoment(Moment);
moment.locale('ru');

const ls = require('store');

export interface AppProps {
    dispatch?: any;
    filters?: any;
    updateFilters?: string;
    authUser: IUser;
    searchString: string;
}

import 'rodal/lib/rodal.css';
import {RATING_URL} from '../../config/urls';

class OrganizationsComponent extends React.Component<AppProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            organizations: [],
            limit: 100,
            offset: 0,
            rodalId: 0,
            showRodal: false,
            rodalEmail: '',
            activeTab: 1,
            bestOrganizations: [],
            onlyWithRating: true,
            totalBest: 0,
            totalWorse: 0,
            worseOrganizations: [],
            loadingW: false,
            loadingB: false,
        };
    }
    componentDidMount() {
        this.getOrganizations(false, '');
    }
    componentWillReceiveProps(nextProps) {
        this.getOrganizations(false, nextProps.searchString);
    }
    subscribe(id: Number, email: string) {
        axios
            .post('https://api.rating.we-change.ru/organization/subscribe/' + id, qs.stringify({
                email: email,
            }), {
                headers: { Authorization: 'Bearer ' + ls.get('access_token') }
            })
            .then(r => {
                if (r.status === 201 ) {
                    // Подписка - Делаем звездочку активной
                    this.setState({ showRodal: false, rodalEmail: '', rodalId: 0 }, () => {
                        const user = this.props.authUser;
                        if (!user.subscriptions) {
                            user.subscriptions = [];
                            user.subscriptions.push(id);
                        } else {
                            user.subscriptions.push(id);
                        }
                        this.props.dispatch(setAuthUser(user));
                        this.forceUpdate();
                    });
                }
                if (r.status === 200) {
                    // Отписка - Делаем звездочку неактивной
                    this.setState({ showRodal: false, rodalEmail: '', rodalId: 0 }, () => {
                        const user = this.props.authUser;
                        user.subscriptions.splice(user.subscriptions.indexOf(id), 1);
                        this.props.dispatch(setAuthUser(user));
                        this.forceUpdate();
                    });
                }
                if (r.status === 406) {
                    // нет emailа - запрашиваем, сохраняем и пробуем снова подписаться
                    this.setState({ showRodal: true, rodalId: id });
                }
            })
            .catch(e => {
                if (e.response.status === 406) {
                    // нет emailа - запрашиваем, сохраняем и пробуем снова подписаться
                    this.setState({ showRodal: true, rodalId: id });
                } else {
                    console.log(e);
                }
            });
    }
    saveEmail(email) {
        axios
            .put('https://user.test.we-change.ru/user/email', qs.stringify({
                email: email,
            }), {
                headers: { Authorization: 'Bearer ' + ls.get('access_token') }
            })
            .then(r => {
                if (r.status === 200 && r.data.code === 200) {
                    this.subscribe(this.state.rodalId, email);
                }
            })
            .catch(e => console.log(e));
    }
    getOrganizations(append: boolean, query: string) {
        const category = (this.props.filters && Number(this.props.filters.category) > 0) ? Number(this.props.filters.category) : null,
            userCategories = [],
            userOrganizations = [],
            sphere = (this.props.filters && Number(this.props.filters.sphere) > 0) ? Number(this.props.filters.sphere) : null,
            guids = (this.props.filters && this.props.filters.region !== '') ? [this.props.filters.region] : [];
        this.props.authUser.organizations.forEach(o => {
            userOrganizations.push(o.organization_id);
        });
        this.props.authUser.categories.forEach(c => {
            userCategories.push(c.category_id);
        });
        this.props.authUser.regions.forEach(r => {
            guids.push(r.region_fias);
        });
        const categoryFilter = category ? {'term': {'category_id': category}} : null,
            sphereFilter = sphere ? {'term': {'sphere_id': sphere}} : null,
            qFilter = query.length > 0 ? [{
                'multi_match': {
                    'type': 'most_fields',
                    'query': query,
                    'fields': ['title', 'address', 'parent_name']
                }
            }] : [];
        const filter = [],
            rFilter = [];
        if (guids.length > 0) {
            guids.forEach(guid => {
                rFilter.push({'match': {'region_fias': guid }});
            });
          // @ts-ignore
          qFilter.push(rFilter);
        }
        if (categoryFilter) {
            filter.push(categoryFilter);
        }
        if (sphereFilter && !categoryFilter) {
            filter.push(sphereFilter);
        }
        if (userCategories.length > 0) {
          // @ts-ignore
          qFilter.push({'terms': {'category_id': userCategories}});
        }
        if (userOrganizations.length > 0) {
            // @ts-ignore
            qFilter.push({'terms': {'id': userOrganizations}});
        }
        if (this.state.onlyWithRating) {
          filter.push({
            'range': {
              'nrating': {
                'gt': 0
              }
            }
          });
        }

        this.setState({ loadingB: true }, () => {
          axios.post('https://api.rating.we-change.ru/search/organizations/_search',
            {
              'from': this.state.offset, 'size': this.state.limit,
              'query': {
                'bool': {
                  'must': qFilter,
                  'filter': filter
                }
              },
              'sort' : [
                { 'rating' : {'unmapped_type': 'float', 'order' : 'desc'}},
              ],
            }).then(r => {
            if ((r.data.hits && r.data.hits.hits.length > 0) || append) {
              let bestOrganizations = [];
              if (append) {
                bestOrganizations.push(...this.state.bestOrganizations);
              }
              bestOrganizations.push(...r.data.hits.hits);
              axios.get(RATING_URL + '/landing-stat/').then(res => {
                bestOrganizations.forEach((bo, i) => {
                  bestOrganizations[i]['lr'] = res.data[bo._id] ? res.data[bo._id] : 0;
                });
                bestOrganizations.sort((a, b) => {
                  if (a.lr > b.lr) {
                    return - 1;
                  } else if (a.lr < b.lr) {
                    return 1;
                  } else {
                    return 0;
                  }
                });
                axios.get('https://api.rating.we-change.ru/landing-count/').then(response => {
                  bestOrganizations.forEach((bo, i) => {
                    bestOrganizations[i]['count'] = response.data[bo._id] ? response.data[bo._id] : 0;
                  });
                  this.setState({
                    bestOrganizations,
                    totalBest: r.data.hits.total,
                    loadingB: false
                  });
                }).catch((e) => {console.log(e); });
              }).catch((e) => {console.log(e); });
            } else {
              this.setState({
                  bestOrganizations: [],
                  totalBest: r.data.hits.total,
                  loadingB: false
                },
              );
            }
          }).catch((e) => {
            console.log(e);
          });
        });

        this.setState({ loadingW: true }, () => {
          axios.post('https://api.rating.we-change.ru/search/organizations/_search',
            {
              'from': this.state.offset, 'size': this.state.limit,
              'query': {
                'bool': {
                  'must': qFilter,
                  'filter': filter
                }
              },
              'sort' : [
                { 'rating' : { 'unmapped_type': 'float', 'order' : 'asc'}},
              ],
            }).then(r => {
            if ((r.data.hits && r.data.hits.hits.length > 0) || append) {
              let worseOrganizations = [];
              if (append) {
                worseOrganizations.push(...this.state.worseOrganizations);
              }
              worseOrganizations.push(...r.data.hits.hits);
              axios.get(RATING_URL + '/landing-stat/').then(res => {
                worseOrganizations.forEach((bo, i) => {
                  worseOrganizations[i]['lr'] = res.data[bo._id] ? res.data[bo._id] : 0;
                });
                worseOrganizations.sort((a, b) => {
                  if (a.lr > b.lr) {
                    return 1;
                  } else if (a.lr < b.lr) {
                    return -1;
                  } else {
                    return 0;
                  }
                });
                axios.get('https://api.rating.we-change.ru/landing-count/').then(response => {
                  worseOrganizations.forEach((bo, i) => {
                    worseOrganizations[i]['count'] = response.data[bo._id] ? response.data[bo._id] : 0;
                  });
                  this.setState({
                    worseOrganizations,
                    totalWorse: r.data.hits.total,
                    loadingW: false
                  });
                }).catch((e) => {console.log(e); });
              }).catch((e) => {console.log(e); });
            } else {
              this.setState({
                  worseOrganizations: [],
                  totalWorse: r.data.hits.total,
                  loadingW: false,
                },
              );
            }
          }).catch((e) => {
            console.log(e);
          });
        });

    }
    render() {
        const aUser = this.props.authUser;
        const rateStatuses = ['', 'Очень плохо', 'Плохо', 'Нормально', 'Хорошо', 'Отлично'];
        return(
            <div className='OrganizationsComponent'>
            <div className='wrapper OrganizationsComponent__wrapper'>
                        <div className='OrganizationsComponent__head'>
                            <h2 className='OrganizationsComponent__h2'>Организации</h2>
                            <button className={(this.state.activeTab === 0 ? 'sort OrganizationsComponent__sort' : 'sort OrganizationsComponent__sort sort_up')} onClick={ () => {(this.state.activeTab === 0 ? this.setState({activeTab: 1}) : this.setState({activeTab: 0})); }}>{(this.state.activeTab === 0 ? 'Сначала лучшие' : 'Сначала худшие')}</button>
                        </div>
                        <div className='OrganizationsComponent__cell'>
                          {(this.state.loadingB || this.state.loadingW) ? (
                            <div className='organisation-list'>
                              <div
                                className={ 'preLoader active' }
                                style={ {
                                  zIndex: 999,
                                  height: '100%',
                                  width: '100%',
                                  background: 'white',
                                } }
                              >
                                <div
                                  style={ {
                                    margin: '0 auto',
                                    flex: 1,
                                    width: 142,
                                  } }

                                  className={ 'preLoader__spinner' }>
                                  <i className='circle-preloader' />
                                </div>
                              </div>
                            </div>
                            ) : (
                            <div className='organisation-list'>
                              {(this.state.activeTab === 0 ? this.state.bestOrganizations : this.state.worseOrganizations).map((o, idx) => (
                                <React.Fragment>
                                  {o.count !== 0 && (
                                    <div key={'org-' + idx} className='organisation-list__card organisation-list__card_light'>
                                      <div className='info organisation-list__info'>
                                        <div
                                          className={(!this.props.authUser.subscriptions || this.props.authUser.subscriptions.indexOf(o._source.id) === -1) ? 'star info__star organisation-list__star' : 'star info__star organisation-list__star organisation-list__star_active'}
                                          onClick={this.subscribe.bind(this, o._source.id, '')}>
                                          <img src={iconStar}/>
                                        </div>
                                        <div className='tips'>
                                          <div className='tips__label'>
                                            Получать уведомления об оценках
                                          </div>
                                        </div>
                                        <Link to={'/organizations/' + o._source.id}>
                                          <div className='info__name organisation-list__name'>
                                            {o._source.category.title}
                                          </div>
                                          <div className='comment organisation-list__comment'>
                                            <span style={{color: o.lr <= 40 ? '#FF0000' : (o.lr > 65 ? '#34bf01' : '#ec7a00')}}>{o.lr.toFixed(2)}</span> {o._source.title}
                                          </div>
                                          <div className='info__name organisation-list__name'>
                                            {o._source.address}
                                          </div>
                                        </Link>
                                        { /*<div className={'info info_rating-' + Math.round(o._source.rating)}>
                                                    <div className='info__evaluation'>
                                                        <img className='info__img' src={faceImg[Math.round(o._source.rating)]}/>
                                                        <div className={o._source.rating > 0 ? 'info__count' : 'hide'}>{(o._source.rating).toFixed(2)}</div>
                                                    </div>
                                                </div>*/ }
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                              {(this.state.activeTab === 0 ? this.state.bestOrganizations : this.state.worseOrganizations).map((o, idx) => (
                                <React.Fragment>
                                  {o.count === 0 && (
                                    <div key={'org-' + idx} className='organisation-list__card organisation-list__card_light'>
                                      <div className='info organisation-list__info'>
                                        <div
                                          className={(!this.props.authUser.subscriptions || this.props.authUser.subscriptions.indexOf(o._source.id) === -1) ? 'star info__star organisation-list__star' : 'star info__star organisation-list__star organisation-list__star_active'}
                                          onClick={this.subscribe.bind(this, o._source.id, '')}>
                                          <img src={iconStar}/>
                                        </div>
                                        <div className='tips'>
                                          <div className='tips__label'>
                                            Получать уведомления об оценках
                                          </div>
                                        </div>
                                        <Link to={'/organizations/' + o._source.id}>
                                          <div className='info__name organisation-list__name'>
                                            {o._source.category.title}
                                          </div>
                                          <div className='comment organisation-list__comment'>
                                            {o._source.title}
                                          </div>
                                          <div className='info__name organisation-list__name'>
                                            {o._source.address}
                                          </div>
                                        </Link>
                                        { /*<div className={'info info_rating-' + Math.round(o._source.rating)}>
                                                    <div className='info__evaluation'>
                                                        <img className='info__img' src={faceImg[Math.round(o._source.rating)]}/>
                                                        <div className={o._source.rating > 0 ? 'info__count' : 'hide'}>{(o._source.rating).toFixed(2)}</div>
                                                    </div>
                                                </div>*/ }
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                              {(!this.state.bestOrganizations.length && !this.state.worseOrganizations.length) && (
                                <div className='organisation-list__card organisation-list__card_light'>
                                  <div className='info organisation-list__info'>
                                    <div className='comment organisation-list__comment'>
                                      Не найдено организаций
                                    </div>
                                  </div>
                                </div>
                                )}
                              {((this.state.activeTab === 0 && this.state.bestOrganizations.length < this.state.totalBest)
                                || (this.state.activeTab !== 0 && this.state.worseOrganizations.length < this.state.totalWorse)) && (
                                <div className='organisation-list__card organisation-list__card_more'>
                                  <button
                                    onClick={ () => {
                                      this.setState(prevState => {
                                        return { offset: prevState.offset + 10 };
                                      }, () => this.getOrganizations(true, this.props.searchString));
                                    }}
                                    className='button button_more'
                                  >
                                    Показать еще
                                  </button>
                                </div>
                              )}
                              <div className='organisation-list__card organisation-list__card_more'>
                                <button
                                  onClick={ () => {
                                    this.setState(prevState => {
                                      return { onlyWithRating: !prevState.onlyWithRating };
                                    }, () => this.getOrganizations(false, this.props.searchString));
                                  }}
                                  className='button button_more'
                                >
                                  { this.state.onlyWithRating ? 'Показывать организации без оценок' : 'Показывать организации только с оценками' }
                                </button>
                              </div>
                            </div>
                          )}

                        </div>
                        <div className='OrganizationsComponent__cell'>
                            <TopStatComponent  authUser={aUser}/>
                        </div>
                    </div>
                <p>Организации</p>
                                <table>
                                    <thead>
                                    <th>Название</th>
                                    <th>Адрес</th>
                                    <th>Оценка</th>
                                    <th>Категория</th>
                                    <th>Дата</th>
                                    <th>Подписка</th>
                                </thead>
                                <tbody>
                                {this.state.organizations.map((o, idx) => (
                                    <tr key={'org-' + idx}>
                                        <td><Link to={routeCodes.ORGANIZATIONS + '/' + o._source.id}>{o._source.title}</Link></td>
                                        <td>{o._source.address}</td>
                                        <td>{rateStatuses[Math.round(o._source.rating)]}</td>
                                        <td>{o._source.category.title}</td>
                                        <td>{moment(o._source.created_at).format('LLL')}</td>
                                        <td>{
                                            (!this.props.authUser.subscriptions || this.props.authUser.subscriptions.indexOf(o._source.id) === -1) ? (
                                                <span onClick={this.subscribe.bind(this, o._source.id, '')}>Подписаться</span>
                                            ) : (
                                                <span onClick={this.subscribe.bind(this, o._source.id, '')}>Отписаться</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                               </tbody>
                            </table>
                <Rodal
                    onClose={() => {
                        this.setState({showRodal: false, rodalEmail: '', rodalId: 0});
                    }}
                    visible={this.state.showRodal} width={600} height={273}>
                    <div className='subscribe'>
                        <div className='subscribe__title'>
                            Необходимо ввести e-mail
                        </div>
                        <div className='subscribe__message'>
                            Чтобы получать уведомления о плохих оценках организаций, необходимо указать e-mail, на который будет приходить информация
					    </div>
                        <input
                                className='subscribe__input'
                                name='name'
                                onChange={(e) => {this.setState({rodalEmail: e.target.value}); }}
                                value={this.state.rodalEmail}
                                type='text'
                                placeholder='Введите e-mail...'
                                />
                    </div>

                    <button
                        type='button'
                        onClick={ this.saveEmail.bind(this, this.state.rodalEmail) }
                        className='button subscribe__button'>СОХРАНИТЬ
                    </button>
                </Rodal>
            </div>
        );
    }
}

export default connect<{}, {}, any>((state: any) => {
    return {
        searchString: state.app.searchString,
        authUser: state.app.authUser,
        loadingApp: state.app.loadingApp,
        filters: state.app.filters,
        updateFilters: state.app.updateFilters,
    };
})(OrganizationsComponent);
