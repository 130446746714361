import * as React from 'react';
import Answer from './Answer';
import AnswerRating from './AnswerRating';
import {Link} from 'react-router-dom';
import axios from 'axios';

export interface Props {
  id: number;
  answers: any[];
}

class Results extends React.Component<Props, any> {

  constructor(props) {
    super(props);
    this.state = {
      quiz: false,
      answers: [],
    };
  }

  update() {
    axios.get('https://api.rating.we-change.ru/quiz/' + this.props.id).then((r) => {
      let data = r.data;

      let answers = [];

      this.props.answers.map((item, i) => {
        item['answer'] = typeof item.answer === 'string' ? JSON.parse(item.answer) : item.answer;
        answers.push(item);
      });
      this.setState({
        quiz: data,
        answers,
      }, () => this.forceUpdate());
    }).catch(function (e) {
      console.log(e);
    });
  }

  componentDidMount() {
    this.update();
  }

  componentWillReceiveProps() {
    this.update();
  }

  getRating(qguid, qtype) {
    let p1 = 0;
    let p2 = 0;
    this.state.quiz.questions.map((question, qi) => {
      if (question.guid === qguid) {
        question.answers.map((option, oi) => {
          let qu = 0;
          let au = 0;
          this.state.answers.map((answer, i) => {
            if (answer.answer && answer.answer[qguid]) {
              qu++;
              for (let key in answer.answer[qguid]) {
                if (qtype === 'range4') {
                  if (option.code === key) {
                    au++;
                  }
                } else {
                  if (option.guid === key) {
                    au++;
                  }
                }
              }
            }
          });
          if (question.scale && question.answers.length === 5) {
            if (oi == 0 || oi == 1) {
              p2 = p2 + Math.round((au / qu * 100) * 100) / 100
            }
            if (oi == 2 || oi == 3) {
              p1 = p1 + Math.round((au / qu * 100) * 100) / 100
            }
          } else if (!question.scale && question.answers.length === 5) {
            if (oi == 0 || oi == 1) {
              p1 = p1 + Math.round((au / qu * 100) * 100) / 100
            }
            if (oi == 2 || oi == 3) {
              p2 = p2 + Math.round((au / qu * 100) * 100) / 100
            }
          } else if (question.answers.length == 3 && question.answers[0].title === "Да") {
            if (oi == 0) {
              p1 = p1 + Math.round((au / qu * 100) * 100) / 100
            }
            if (oi == 1) {
              p2 = p2 + Math.round((au / qu * 100) * 100) / 100
            }
          }
        });
      }
    })
    if (p1 - p2 !== 0) {
      return (p1 - p2).toFixed(2);
    }
    return '';
  }

  render() {
    let quiz = this.state.quiz,
      rates = ['Очень плохо', 'Плохо', 'Нормально', 'Хорошо', 'Отлично'];
    return (
      <div className='poll-result__card'>
        {this.state.quiz !== false &&
        <div>
          <div className='poll-result__title'>
            Итоги опроса
          </div>
          {quiz.questions.map((question, i) => (
            <div key={'q-' + i} className={'question-box'}>
              <div className='question-box__title'>
                <span style={{color: '#ff5722'}}>({this.getRating(question.guid, question.type)})</span> {question.title}
              </div>
              {question.type !== 'range' ? (
                <React.Fragment>
                  {question.answers.map((option, j) => (
                    <Answer key={'a-' + j} option={option} question={question}
                            answers={this.state.answers} />
                  ))}
                </React.Fragment>
              ) : (<React.Fragment>
                {rates.map((r, k) => (
                  <AnswerRating key={'ar-' + k} i={k + 1}
                                qId={question.guid} quizId={this.state.quiz.id}
                                answers={this.state.answers}/>
                ))}
              </React.Fragment>)}
            </div>))}
        </div>
        }
      </div>
    );
  }
}

export default Results;
