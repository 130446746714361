import * as React from 'react';


export default class StartLoading extends React.Component <{}, {}> {
    render() {
        return (
            <div
                className={ 'preLoader active' }
                style={ {
                    zIndex: 999,
                    height: '100%',
                    width: '100%',
                    background: 'white',
                } }
            >
                <div
                    style={ {
                        margin: '0 auto',
                        flex: 1,
                        width: 142,
                    } }

                    className={ 'preLoader__spinner' }>
                    ЗАГРУЗКА
                </div>
            </div>
        );
    }
}