import * as React from 'react';
import axios from 'axios';
import {IUser} from '../../interfaces';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

const Moment = require('moment');
const MomentRange = require('moment-range');

const ls = require('store');

const faceImg = [];
faceImg[1] = require('../../assets/img/face-1@2x.png');
faceImg[2] = require('../../assets/img/face-2@2x.png');
faceImg[3] = require('../../assets/img/face-3@2x.png');
faceImg[4] = require('../../assets/img/face-4@2x.png');
faceImg[5] = require('../../assets/img/face-5@2x.png');

const iconDownload = require('../../assets/img/icon-download.svg');

import HeaderComponent from './HeaderComponent';
import Results from './Results';
import {RATING_URL} from '../../config/urls';

let moment = MomentRange.extendMoment(Moment);
moment.locale('ru');

export interface AppProps {
    authUser: IUser;
    match?: any;
}

class OrgPageComponent extends React.Component<AppProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            organization: {},
            rate1: 0,
            rate2: 0,
            rate3: 0,
            rate4: 0,
            rate5: 0,
            count: 0,
            comments: [],
            commentsLimit: 10,
            step: 10,
            activeTabIdx: 0,
            quizList: [],
            data: {},
        };
    }
    componentDidMount() {
        this.getOrganization();
        this.getQuizzes();
    }
    getOrganization() {
        axios.get('https://api.rating.we-change.ru/organization/' + this.props.match.params.id)
            .then(r => {
                const org = r.data;
                const count = org.comments ? org.comments.length : 0;
                const commentsWithFeedback = [];
                let rate1 = 0, rate2 = 0, rate3 = 0, rate4 = 0, rate5 = 0;
                if (org.comments) {
                    org.comments.forEach(function (comment) {
                        let value = Math.round(comment.value);
                        switch (value) {
                            case 1:
                                rate1++;
                                break;
                            case 2:
                                rate2++;
                                break;
                            case 3:
                                rate3++;
                                break;
                            case 4:
                                rate4++;
                                break;
                            case 5:
                                rate5++;
                                break;
                            default:
                                break;
                        }
                        if (comment.feedback && comment.feedback.length > 0) {
                            commentsWithFeedback.push(comment);
                        }
                    });
                    org.comments = commentsWithFeedback;
                    console.log(org.comments);
                    this.getComments(org.comments);
                }

              axios.get(RATING_URL + '/landing-stat/').then(res => {
                org['lr'] = res.data[org.id] ? res.data[org.id] : 0;
                this.setState({
                  organization: org,
                  rate1: rate1,
                  rate2: rate2,
                  rate3: rate3,
                  rate4: rate4,
                  rate5: rate5,
                  // count: count,
                });
              }).catch((e) => { console.log(e); });
            })
            .catch(e => {
                console.log(e);
            });
    }
    getQuizzes() {
      axios.get('https://api.rating.we-change.ru/stat/view?id=' + this.props.match.params.id).then((r) => {
        let data = {},
          quizList = [];
        r.data.map((item, i) => {
          if (!data[item.quiz_id]) {
            data[item.quiz_id] = [];
            quizList.push({
              id: item.quiz_id,
              title: item.title,
            });
          }
          data[item.quiz_id].push(item);
        });
        this.setState({
          quizList,
          data,
          count: r.data.length
        });
      }).catch(function (e) {
        console.log(e);
      });
    }
    getComments(comments) {

        let commentsLimit = this.state.commentsLimit;
        if (commentsLimit >= comments.length) {
            commentsLimit = comments.length;
        }

        let commentsPart = comments.length - commentsLimit > 0 ? comments.slice(0, -(comments.length - commentsLimit)) : comments;

        this.setState({
            comments: commentsPart
        });

        let nextCommentsCount = (comments.length - commentsLimit);
        if (nextCommentsCount < 1) {
            this.setState({
                commentsLimit: false
            });
        }
    }

    getMoreComments() {
        let nextLimit = this.state.commentsLimit + this.state.step;

        this.setState({
            commentsLimit: nextLimit
        }, () => {
            this.getComments(this.state.organization.comments);
        });
    }

    render() {
        const org = this.state.organization;
        const rateStatuses = ['', 'Очень плохо', 'Плохо', 'Нормально', 'Хорошо', 'Отлично'];
        return(
            <div className='app'>
                <HeaderComponent />

                {org.id && (
                    <div className="OrgPageComponent">
                        <div className="wrapper OrgPageComponent__wrapper">
                            <div className="breadcrumbs hide">
                                <a href="#" className="breadcrumbs__item">ГЛАВНАЯ / ОРГАНИЗАЦИИ</a>
                            </div>
                            <div className="OrgPageComponent__cell">
                                <div className="org-page">
                                    <h2 className="heading heading_h2 org-page__heading">
                                    {org.title}
                                    </h2>
                                    <div className="org-page__item">
                                        <b>Адрес(а):</b>
                                        <p>{org.address}</p>
                                    </div>

                                    <div className="org-page__item">
                                        <b>Телефон:</b>
                                        <p>{org.phone}</p>
                                    </div>
                                    {org.site && (
                                    <div className="org-page__item">
                                        <b>Сайт:</b>
                                        <p><a className="text text_link" target='_blank' href={org.site.indexOf('http:') === -1 ? 'http://' + org.site + '/' : org.site}>{org.site}</a></p>
                                    </div>)}
                                </div>

                                <div className={'LastCommentsComponent OrgPageComponent__LastCommentsComponent'}>
                                  <div className={ `tabs` }>
                                    <div
                                      role='presentation'
                                      onClick={ () => { this.setState({ activeTabIdx: 0 }); }}
                                      className={ `tabs__tab ` + (this.state.activeTabIdx === 0 ? 'tabs__tab_active' : '') }
                                    >
                                      Последние комментарии
                                    </div>
                                    {this.state.quizList.map((item, i) => (
                                      <div
                                        key={ 'tab-' + i }
                                        role='presentation'
                                        onClick={ () => { this.setState({ activeTabIdx: (i + 1) }); }}
                                        className={ `tabs__tab ` + (this.state.activeTabIdx === (i + 1) ? 'tabs__tab_active' : '') }
                                      >
                                        {this.state.quizList.length > 1 ? 'Результаты №' + item.id : 'Результаты'}
                                      </div>

                                    ))}
                                  </div>
                                  {this.state.activeTabIdx === 0 && (<React.Fragment>
                                    <div className="rating-list rating-list_recent-ratings LastCommentsComponent__rating-list">
                                      {org.comments && this.state.comments.length ? (this.state.comments.map((c, idx) => (
                                        <div key={'comment-' + idx} className="card rating-list__card">
                                          <div className="info rating-list__info">
                                            <div className="info__left">
                                              <div className="info__name">
                                                {c.name}
                                              </div>
                                              <div className="text text_bold">
                                                Оценка № {c.id}
                                              </div>
                                            </div>
                                            <div className="info__right">
                                              <div className="info__date">
                                                {moment(c.date).format('LLL')}
                                              </div>
                                              <div className="evaluation">
                                                <img className="evaluation__img" src={faceImg[Math.round(c.value)]}/>
                                                {rateStatuses[Math.round(c.value)]}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="comment">
                                            {c.feedback}
                                            <div className="company-name">
                                              {c.organization.title}
                                            </div>
                                          </div>
                                        </div>
                                      ))) : ''}
                                    </div>
                                      <div
                                        className={ this.state.commentsLimit && org.comments && org.comments.length > 0 ? 'text text_link OrgPageComponent__text OrgPageComponent__text_link' : 'hide'}
                                        onClick={ (e) => {
                                          this.getMoreComments()
                                        }}
                                      >
                                        Показать еще
                                      </div>
                                    </React.Fragment>
                                  )}
                                  {this.state.quizList.map((item, i) => (
                                    <React.Fragment>
                                      {this.state.activeTabIdx === (i + 1) && (
                                        <React.Fragment key={'res-pane-' + i}>
                                          {this.state.data[item.id] && (
                                            <Results answers={this.state.data[item.id]} id={item.id}/>
                                          )}
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </div>
                            </div>
                            <div className='OrgPageComponent__cell'>
                                <aside className='aside'>
                                    <div className='eval-organization'>
                                        <div className='text text_bold eval-organization__text'>Оценка организации</div>
                                        <div className={'eval-organization__count eval-organization__count_t-' + Math.round(org.rating)}>
                                          {/*<img className='eval-organization__img' src={faceImg[Math.round(org.rating)]}/>*/}
                                          <span style={{color: org.lr <= 40 ? '#FF0000' : (org.lr > 65 ? '#34bf01' : '#ec7a00')}}>{org.lr.toFixed(2)}</span>
                                        </div>
                                        <div className="eval-organization__rating">
                                            {rateStatuses[Math.round(org.rating)]}
                                            <div className="eval-organization__label">
                                                Всего оценок: {this.state.count ? this.state.count  : 0}
                                            </div>
                                          { /*<div className="graph eval-organization__graph">
                                                <div className="graph__item graph__item_type-1">
                                                    <div className="graph__line" style={{ height:((this.state.rate1 > 0 ? this.state.rate1 : 1)/(this.state.count ? this.state.count : 1)*101)+'px'}}>></div>
                                                    <div className="graph__counter">{this.state.rate1}</div>
                                                </div>
                                                <div className="graph__item graph__item_type-2">
                                                <div className="graph__line" style={{ height:((this.state.rate2 > 0 ? this.state.rate2 : 1)/(this.state.count ? this.state.count : 1)*101)+'px'}}>></div>
                                                    <div className="graph__counter">{this.state.rate2}</div>
                                                </div>
                                                <div className="graph__item graph__item_type-3">
                                                <div className="graph__line" style={{ height:((this.state.rate3 > 0 ? this.state.rate3 : 1)/(this.state.count ? this.state.count : 1)*101)+'px'}}>></div>
                                                    <div className="graph__counter">{this.state.rate3}</div>
                                                </div>
                                                <div className="graph__item graph__item_type-4">
                                                <div className="graph__line" style={{ height:((this.state.rate4 > 0 ? this.state.rate4 : 1)/(this.state.count ? this.state.count : 1)*101)+'px'}}>></div>
                                                    <div className="graph__counter">{this.state.rate4}</div>
                                                </div>
                                                <div className="graph__item graph__item_type-5">
                                                <div className="graph__line" style={{ height:((this.state.rate5 > 0 ? this.state.rate5 : 1)/(this.state.count ? this.state.count : 1)*101)+'px'}}>></div>
                                                    <div className="graph__counter">{this.state.rate5}</div>
                                                </div>
                                            </div>*/ }
                                        </div>
                                    </div>
                                    <a target="_blank" href={'https://api.rating.we-change.ru/organization/xlsx/' + this.props.match.params.id + '?token=' + ls.get('access_token')}>
                                        <div className="button OrgPageComponent__button">
                                            <img className="OrgPageComponent__icon" src={iconDownload}/>
                                            <div className="OrgPageComponent__label">Выгрузить результаты</div>
                                        </div>
                                    </a>
                                </aside>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default connect<{}, {}, any>((state: any) => {
    return {
        authUser: state.app.authUser,
    };
})(OrgPageComponent);
