import * as React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {AUTH_LINK, LOGOUT_LINK} from '../config/urls';

const qs = require('qs');
const ls = require('store');

export interface AppProps {
}

class Register extends React.Component<AppProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      sid: '',
      password: '',
      password_c: '',
      agreeValue: false,
      auto_report: false
    };
  }

  componentDidMount() {
    if (ls.get('access_token')) {
      ls.remove('access_token');
      window.location.href = LOGOUT_LINK + '?token=' + ls.get('access_token') + '&redirect_uri=' + encodeURIComponent(window.location.href);
    } else {
      const queryHash = window.location.hash.substring(1);
      axios.get('https://api.rating.we-change.ru/user/' + queryHash).then((r) => {
        this.setState({
          email: r.data.email,
          name: r.data.profile.name,
          surname: r.data.profile.surname,
          patronymic: r.data.profile.patronymic,
          type: r.data.type,
          phone: r.data.phone,
          password: '',
          id: r.data.id,
          sid: queryHash
        });
      });
    }
  }

  register() {
    if (this.state.password.length < 1) {
      alert('Введите пароль');
      return;
    }
    if (this.state.password !== this.state.password_c) {
      alert('Пароли не совпадают');
      return;
    }
    if (!this.state.agreeValue) {
      alert('Примите соглашение');
      return;
    }
    axios.post('https://api.rating.we-change.ru/user/register/' + this.state.sid, qs.stringify({
      password: this.state.password,
      gender: this.state.gender,
      post: this.state.post,
      email: this.state.email,
      name: this.state.name,
      surname: this.state.surname,
      patronymic: this.state.patronymic,
      type: this.state.type,
      phone: this.state.phone,
      auto_report: this.state.auto_report,
      date_of_birth: new Date(this.state.dob).getTime() === new Date(this.state.dob).getTime() ? new Date(this.state.dob).toISOString().slice(0, 10) : '',
      sc: 'true'
    })).then(r => {
      if (r.data && r.data.text) {
        alert(r.data.text);
        window.location.href = AUTH_LINK;
      }
    }).catch(e => {
      console.log(e);
    });
  }

  render() {
    return (
      <>
        <div className='modal'>
          <div className='modal__page'>
            <div className='modal__wrap'>
              <h1 className='heading heading_h1 modal__heading'>Добро пожаловать!</h1>
              <div className='text text_sub modal__text'>
                Присоединяйтесь к сообществу неравнодушных граждан
                прямо сейчас
              </div>
              <div className='modal__form'>
                <div className='modal__card'>
                  <div className='reg__form'>
                    <h3 className='heading heading_h3'>
                      Регистрация
                    </h3>
                    <input className='reg__input' type='password' placeholder='Пароль...'
                           value={this.state.password}
                           name='password'
                           onChange={(e) => this.setState({ password: e.target.value })}
                    />
                    <input className='reg__input' type='password' placeholder='Повторите пароль...'
                           value={this.state.password_c}
                           name='password_с'
                           onChange={(e) => this.setState({ password_c: e.target.value })}
                    />
                    <input className='reg__input' type='text' placeholder='Должность...'
                           value={this.state.post}
                           name='post'
                           onChange={(e) => this.setState({ post: e.target.value })}
                    />
                    <h4 className='heading heading_h4'>
                      Дата рождения
                    </h4>
                    <input className='reg__input' type='date' placeholder='Дата рождения...'
                           value={this.state.dob}
                           name='dob' max={(new Date().toISOString()).slice(0, 10)}
                           onChange={(e) => this.setState({ dob: e.target.value })}
                    />
                    <select value={this.state.gender} className='reg__input' onChange={(e) => this.setState({ gender: e.target.value })}>
                      <option value={0}>Укажите пол</option>
                      <option value={1}>Женский</option>
                      <option value={2}>Мужской</option>
                    </select>
                    <div className='reg__next'>
                      <label className='checkbox'>
                        <input type='checkbox' checked={this.state.agreeValue}
                               onChange={(e) => this.setState({ agreeValue: e.target.checked })}
                               name='agreeValue'/>
                        <div className='checkbox__icon'/>
                        <div className='checkbox__label'>Я принимаю <a target='_blank'
                                                                       href='https://user.test.we-change.ru/terms'>Пользовательское
                          соглашение</a> и <a target='_blank'
                                              href='https://user.test.we-change.ru/rules'>Правила
                          модерации</a></div>
                      </label>
                      <label className='checkbox'>
                        <input type='checkbox' checked={this.state.auto_report}
                               onChange={(e) => this.setState({ auto_report: e.target.checked })}
                               name='auto_report'/>
                        <div className='checkbox__icon'/>
                        <div className='checkbox__label'>Я согласен(на) получать рассылку на свой e-mail</div>
                      </label>
                      <button className='button button_bold reg__button'
                              onClick={() => this.register()}
                      >ЗАРЕГИСТРИРОВАТЬСЯ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect<{}, {}, any>((state: any) => {
  return state;
})(Register);
