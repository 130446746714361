import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import rootReducer from './../reducers';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

const devTools = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null;
let initialState = {};

// Creating store
// Remove "serverSagas" and "sagaOptions" params
// if you are not using server rendering
export default (serverSagas = null, sagaOptions = {}) => {
  let store = null;
  let middleware = null;

  const history = createHistory();
  const logger  = createLogger();
  const rMiddleware = routerMiddleware(history);
  if (!IS_PRODUCTION) {
    // In development mode beside sagaMiddleware
    // logger and DevTools are added
      middleware = applyMiddleware(logger, rMiddleware);
      store = createStore(
          rootReducer,
          devTools,
          middleware
      );
  } else {
      store = createStore(
          rootReducer
      );
  }


  return {
    store,
    history,
  };
};
