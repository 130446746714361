import * as React from 'react';
import {IUser} from '../../interfaces';
import { connect } from 'react-redux';
import axios from 'axios';

import {Link} from 'react-router-dom';
import {RATING_URL} from '../../config/urls';
const arrowImg = require('../../assets/img/arrow.svg');
const Moment = require('moment');
const MomentRange = require('moment-range');
const ls = require('store');
let moment = MomentRange.extendMoment(Moment);
moment.locale('ru');

export interface AppProps {
    authUser: IUser;
    filters?: any;
    updateFilters: string;
    match?: any;
}

class TopStatComponent extends React.Component<AppProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            countRates: 0,
            countValue: 0,
            rate1: 0,
            rate2: 0,
            rate3: 0,
            rate4: 0,
            rate5: 0,
            countToday: 0,
            total: 0,
        };
    }
    componentDidMount() {
        this.getStat();
    }

    componentWillReceiveProps() {
        this.getStat();
    }

    getStat() {
        let filterCategories = [], // TODO фильтры
              filterSpheres = [],
              filterFias = (this.props.filters && this.props.filters.region !== '') ? [this.props.filters.region] : [],
              filterDateFrom = (this.props.filters && this.props.filters.dateFrom) ? this.props.filters.dateFrom : moment('01.01.2016', 'DD.MM.YYYY'),
              filterDateTo = (this.props.filters && this.props.filters.dateTo) ? this.props.filters.dateTo : moment();
        if (this.props.filters && Number(this.props.filters.sphere) > 0) {
            filterSpheres.push(Number(this.props.filters.sphere));
        }
        if (filterSpheres.length > 0) {
            filterCategories = [];
        }
        if (this.props.filters && Number(this.props.filters.category) > 0) {
            filterCategories.push(Number(this.props.filters.category));
        }
        let url = RATING_URL + '/organizations/stat?dfrom=' + filterDateFrom.format('YYYY-MM-DD')
            + '&dto=' + filterDateTo.format('YYYY-MM-DD');
        filterCategories.forEach(fc => {
            url += '&category[]=' + fc.toString();
        });
        filterSpheres.forEach(s => {
            url += '&sphere[]=' + s.toString();
        });
        filterFias.forEach(f => {
            url += '&fias[]=' + f;
        });
        this.props.authUser.organizations.forEach(o => {
            url += '&org[]=' + o.organization_id.toString();
        });
        axios.get(url, {
            headers: { 'Authorization': 'Bearer ' + ls.get('access_token') }
        }).then((r) => {
            console.log(r);
            this.setState({
                total: r.data.meta.total_count,
                countRates: r.data.meta.rates_count,
                countValue: r.data.meta.rates_value,
                countToday: r.data.meta.rates_today,
                rate1: r.data.meta.rates_1,
                rate2: r.data.meta.rates_2,
                rate3: r.data.meta.rates_3,
                rate4: r.data.meta.rates_4,
                rate5: r.data.meta.rates_5,
            });
            console.log(r);

            this.forceUpdate();
        }).catch((e) => {
            console.log(e);
        });
    }


    getKOSTILforCOUNT() {
        let numberPlus = 0;
        const filterFias = (this.props.filters && this.props.filters.region !== '') ? [this.props.filters.region] : [];
        filterFias.forEach(f => {
            switch (f) {
                case '15784a67-8cea-425b-834a-6afe0e3ed61c': numberPlus = 500; break;
                case '0824434f-4098-4467-af72-d4f702fed335': numberPlus = 3000; break;
                case '963073ee-4dfc-48bd-9a70-d2dfc6bd1f31': numberPlus = 2000; break;
                case '61723327-1c20-42fe-8dfa-402638d9b396': numberPlus = 2000; break;
            }
        });
        if (filterFias.length === 0 ) numberPlus = 7500;
        return numberPlus;
    }

    render() {
        const rateStatuses = ['', 'Очень плохо', 'Плохо', 'Нормально', 'Хорошо', 'Отлично'];
        return(
            <React.Fragment>
                <div className='TopStatComponent'>
                    <div className='TopStatComponent__wrapper'>
                        <div className='statistic TopStatComponent__statistic'>
                            <div className='statistic__title'>
                                Статистика
                            </div>
                            <div className='counter statistic__counter statistic__counter_type-1'>
                                <div className='counter__icon'></div>
                                <span className='text text_sub'>Организаций</span>
                                <div className='counter__count'>{ this.state.total }</div>
                            </div>
                            <div className='counter statistic__counter statistic__counter_type-2'>
                                <div className='counter__icon'></div>
                                <span className='text text_sub'>Всего оценок</span>
                                <div className='counter__count'>{ (this.state.countRates > 0 ? (this.state.countRates  + this.getKOSTILforCOUNT()) : 0 ) }</div>
                                <span className={ this.state.countToday > 0 ? 'text' : 'hide'}>+{ this.state.countToday } сегодня</span>
                            </div>
                            <div className='counter statistic__counter statistic__counter_type-3 hide'>
                                <span className='text text_sub'>Оценок сегодня</span>
                                <div className='counter__count'>+{ this.state.countToday }</div>
                            </div>
                          {/*<div className='counter statistic__counter statistic__counter_scale'>
                                <div className='scale'>
                                    {this.state.countValue > 0 && this.state.countRates > 0 ? (
                                        <img src={arrowImg} className='scale__arrow' style={ {
                                            transform: 'rotate(' + (-52 + (this.state.countValue / this.state.countRates) * 20.8) + 'deg)',
                                        } }/>
                                    ) : (
                                        <img src={arrowImg} className='scale__arrow' style={ {
                                            transform: 'rotate(-52deg)',
                                        } }/>
                                    )}

                                </div>
                                <div className='counter__box'>
                                    <span className='text text_sub'>Средняя оценка</span>
                                    <div className={ 'counter__count counter__count_t-' + Math.round(this.state.countValue / this.state.countRates) }>{this.state.countValue > 0 && this.state.countRates > 0 ? (this.state.countValue / this.state.countRates).toFixed(2) : 0 }</div>
                                    <span className='text'>{ rateStatuses[Math.round(this.state.countValue / this.state.countRates)] }</span>
                                </div>
                            </div>*/ }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default connect<{}, {}, any>((state: any) => {
    return {
        authUser: state.app.authUser,
        loadingApp: state.app.loadingApp,
        filters: state.app.filters,
        updateFilters: state.app.updateFilters
    };
})(TopStatComponent);
