import * as React from 'react';

const imgFace1 = require('../../assets/img/face-1@2x.png');
const imgFace2 = require('../../assets/img/face-2@2x.png');
const imgFace3 = require('../../assets/img/face-3@2x.png');
const imgFace4 = require('../../assets/img/face-4@2x.png');
const imgFace5 = require('../../assets/img/face-5@2x.png');

const Moment = require('moment');
const MomentRange = require('moment-range');

let moment = MomentRange.extendMoment(Moment);
moment.locale('ru');

export interface Props {
  quizId: number;
  qId: number;
  i: number;
  answers: any[];
}

class AnswerRating extends React.Component<Props, any> {

    constructor(props) {
        super(props);
        this.state = {
            qu: 0,
            au: 0,
        };
    }

    componentWillReceiveProps() {
        let qu = 0,
            au = 0,
            _this = this,
            answers = (this.props.answers);

        answers.map((answer, i) => {
            if (answer.answer[_this.props.quizId]) {
              if (answer.answer[_this.props.quizId][_this.props.qId]) {
                qu++;
                for (let key = 0; key <= 5; key++) {
                  if (answer.answer[_this.props.quizId][_this.props.qId][key]) {
                    if ((6 - _this.props.i) === key) {
                      au++;
                    }
                  }
                }
              }
            }
        });
        _this.setState({
            qu: qu,
            au: au,
        });
    }

    render() {
        let rates = ['Очень плохо', 'Плохо', 'Нормально', 'Хорошо', 'Отлично'];
        let faces = [imgFace5, imgFace4, imgFace3, imgFace2, imgFace1];
        rates = rates.reverse();
        return (<div className='response'>
            <div className='response__text'>
                {rates[this.props.i - 1]}
            </div>
            <div className='response__percent'>
                {this.state.qu > 0 ? (Math.round((this.state.au / this.state.qu * 100) * 100) / 100).toFixed(2) : (0).toFixed(2)}%
                | {this.state.au}
            </div>
            <div className={'response__progress'}>
                <div className='response__line'
                     style={{width: (this.state.qu > 0 ? (Math.round((this.state.au / this.state.qu * 100) * 100) / 100).toFixed(2) : 0) + '%'}}/>
            </div>
        </div>);
    }
}

export default AnswerRating;
