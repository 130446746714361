import * as React from 'react';
import axios from 'axios';
import moment = require('moment');
import {IUser} from '../../interfaces';
import { connect } from 'react-redux';

const faceImg = [];
faceImg[1] = require('../../assets/img/face-1@2x.png');
faceImg[2] = require('../../assets/img/face-2@2x.png');
faceImg[3] = require('../../assets/img/face-3@2x.png');
faceImg[4] = require('../../assets/img/face-4@2x.png');
faceImg[5] = require('../../assets/img/face-5@2x.png');

export interface AppProps {
    authUser: IUser;
    filters?: any;
    updateFilters: string;
}

class BestWorseOrganizationsComponent extends React.Component<AppProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            bestOrganizations: [],
            worseOrganizations: [],
            limit: 3,
            offset: 0,
            activeTab:0
        };
    }

    componentDidMount() {
        this.getOrganizations();
    }
    componentWillReceiveProps() {
        this.getOrganizations();
    }
    getOrganizations() {
        const category = (this.props.filters && Number(this.props.filters.category) > 0) ? Number(this.props.filters.category) : null,
            userCategories = [],
            userOrganizations = [],
            sphere = (this.props.filters && Number(this.props.filters.sphere) > 0) ? Number(this.props.filters.sphere) : null,
            guids = [],
            query = '';
        this.props.authUser.organizations.forEach(o => {
            userOrganizations.push(o.organization_id);
        });
        this.props.authUser.categories.forEach(c => {
            userCategories.push(c.category_id);
        });
        this.props.authUser.regions.forEach(r => {
            guids.push(r.region_fias);
        });
        const categoryFilter = category ? {'term': {'category_id': category}} : null,
            sphereFilter = sphere ? {'term': {'sphere_id': sphere}} : null,
            qFilter = query.length > 0 ? [{
                'multi_match': {
                    'type': 'most_fields',
                    'query': query,
                    'fields': ['title', 'address', 'parent_name']
                }
            }] : [];
        const filter = [],
            rFilter = [];
        if (guids.length > 0) {
            guids.forEach(guid => {
                rFilter.push({'term': {'region_fias': guid }});
            });
        }
        if (categoryFilter) {
            filter.push(categoryFilter);
        }
        if (sphereFilter && !categoryFilter) {
            filter.push(sphereFilter);
        }
        // @ts-ignore
        qFilter.push({'terms': {'category_id': userCategories.length > 0 ? userCategories : [199, 210, 209, 216, 219, 204, 214, 213, 235, 245, 246, 247, 249, 250, 251, 252, 253, 255, 256, 257, 258, 259, 260]}});
        if (userOrganizations.length > 0) {
            // @ts-ignore
            qFilter.push({'terms': {'id': userOrganizations}});
        }
        filter.push({
            'exists': {'field': 'geo'}
        });

        filter.push({
            'range' : {
                'rating' : {
                    'gte' : 1,
                    'lte' : 5,
                }
            }
        });

        this.getBsetOrg(filter, qFilter);
        this.getWorstOrg(filter, qFilter);
    }

    getBsetOrg(filter, qFilter) {
        axios.post('https://api.rating.we-change.ru/search/organizations/_search',
            {
                'from': this.state.offset,
                'size': this.state.limit,
                'query': {
                    'bool': {
                        'must': qFilter,
                        'filter': filter
                    },
                },
                'sort' : [
                    { 'rating' : {'order' : 'desc'}},
                ],

            }).then(r => {
            if (r.data.hits && r.data.hits.hits.length > 0) {
                let organizations = r.data.hits.hits;
                this.setState({
                        bestOrganizations: organizations,
                    },
                );
            } else {
                this.setState({
                        bestOrganizations: [],
                    },
                );
            }
        }).catch((e) => {
            console.log(e);
        });
    }
    getWorstOrg(filter, qFilter) {
        axios.post('https://api.rating.we-change.ru/search/organizations/_search',
            {
                'from': this.state.offset,
                'size': this.state.limit,
                'query': {
                    'bool': {
                        'must': qFilter,
                        'filter': filter
                    },
                },
                'sort' : [
                    { 'rating' : {'order' : 'asc'}},
                ],

            }).then(r => {
            if (r.data.hits && r.data.hits.hits.length > 0) {
                let organizations = r.data.hits.hits;
                this.setState({
                        worseOrganizations: organizations,
                    },
                );
            } else {
                this.setState({
                        worseOrganizations: [],
                    },
                );
            }
        }).catch((e) => {
            console.log(e);
        });
    }

    render() {
        const rateStatuses = ['', 'Очень плохо', 'Плохо', 'Нормально', 'Хорошо', 'Отлично'];
        return(
            <React.Fragment>
                <div className="BsetWorseOrganizationsComponent">
                    <div className="wrapper BsetWorseOrganizationsComponent__wrapper">
                        <div className="tabs BsetWorseOrganizationsComponent__tabs">
                            <div className={this.state.activeTab == 0 ? "tabs__tab tabs__tab_active":"tabs__tab"} onClick={ () => { this.setState({activeTab:0}); }}>Лучшие организации</div>
                            <div className={this.state.activeTab == 1 ? "tabs__tab tabs__tab_active":"tabs__tab"} onClick={ () => { this.setState({activeTab:1}); }}>Худшие организации</div>
                        </div>
                        {this.state.activeTab == 0 ? (
                            <div className="organisation-top">
                                {this.state.bestOrganizations.map((o, idx) => (
                                    <div key={'org-' + idx} className="organisation-top__item">
                                        <div className="organisation-list__card">
                                            <div className="info organisation-list__info">
                                                <div className="info__name organisation-list__name">
                                                    {o._source.category.title}
                                                </div>
                                                <div className="comment organisation-list__comment">
                                                    {o._source.title}
                                                </div>
                                                <div className="info">
                                                    <div className="info__evaluation">
                                                        <img className="info__img" src={faceImg[Math.round(o._source.rating)]}/>
                                                        <div className={'info__count info__count_t-'+Math.round(o._source.rating)}>{(o._source.rating).toFixed(2)}</div>
                                                        <div className="info__evaluation-v">{rateStatuses[Math.round(o._source.rating)]}</div>
                                                        <div className="info__counts">Оценок: 1 125</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="organisation-top">
                                {this.state.worseOrganizations.map((o, idx) => (
                                    <div key={'org-' + idx} className="organisation-top__item">
                                        <div className="organisation-list__card">
                                            <div className="info organisation-list__info">
                                                <div className="info__name organisation-list__name">
                                                    {o._source.category.title}
                                                </div>
                                                <div className="comment organisation-list__comment">
                                                    {o._source.title}
                                                </div>
                                                <div className="info">
                                                    <div className="info__evaluation">
                                                        <img className="info__img" src={faceImg[Math.round(o._source.rating)]}/>
                                                        <div className={'info__count info__count_t-'+Math.round(o._source.rating)}>{(o._source.rating).toFixed(2)}</div>
                                                        <div className="info__evaluation-v">{rateStatuses[Math.round(o._source.rating)]}</div>
                                                        <div className="info__counts">Оценок: 1 125</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );

    }
}


export default connect<{}, {}, any>((state: any) => {
    return {
        authUser: state.app.authUser,
        loadingApp: state.app.loadingApp,
        filters: state.app.filters,
        updateFilters: state.app.updateFilters,
    };
})(BestWorseOrganizationsComponent);
