import * as React from 'react';
import { connect } from 'react-redux';
import { AUTH_LINK } from '../config/urls';

export interface AppProps {
}

class Login extends React.Component<AppProps, any> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        location.href = AUTH_LINK;
    }
    render() {
        return(
            <div>
                <a href={ AUTH_LINK }>
                    <button className='button hero__button'>
                        Войти
                    </button>
                </a>
            </div>
        );
    }

}

export default connect<{}, {}, any>((state: any) => {
    return state;
})(Login);