export default class Regions {
  public static regions = [
    {guid: 'd8327a56-80de-4df2-815c-4f6ab1224c50', name: 'Республика Адыгея'},
    {guid: '6f2cbfd8-692a-4ee4-9b16-067210bde3fc', name: 'Республика Башкортостан'},
    {guid: 'a84ebed3-153d-4ba9-8532-8bdf879e1f5a', name: 'Республика Бурятия'},
    {guid: '5c48611f-5de6-4771-9695-7e36a4e7529d', name: 'Республика Алтай'},
    {guid: '0bb7fa19-736d-49cf-ad0e-9774c4dae09b', name: 'Республика Дагестан'},
    {guid: 'b2d8cd20-cabc-4deb-afad-f3c4b4d55821', name: 'Республика Ингушетия'},
    {guid: '1781f74e-be4a-4697-9c6b-493057c94818', name: 'Кабардино-Балкарская Республика'},
    {guid: '491cde9d-9d76-4591-ab46-ea93c079e686', name: 'Республика Калмыкия'},
    {guid: '61b95807-388a-4cb1-9bee-889f7cf811c8', name: 'Карачаево-Черкесская Республика'},
    {guid: '248d8071-06e1-425e-a1cf-d1ff4c4a14a8', name: 'Республика Карелия'},
    {guid: 'c20180d9-ad9c-46d1-9eff-d60bc424592a', name: 'Республика Коми'},
    {guid: 'de2cbfdf-9662-44a4-a4a4-8ad237ae4a3e', name: 'Республика Марий Эл'},
    {guid: '37a0c60a-9240-48b5-a87f-0d8c86cdb6e1', name: 'Республика Мордовия'},
    {guid: 'c225d3db-1db6-4063-ace0-b3fe9ea3805f', name: 'Республика Саха / Якутия'},
    {guid: 'de459e9c-2933-4923-83d1-9c64cfd7a817', name: 'Республика Северная Осетия - Алания'},
    {guid: '0c089b04-099e-4e0e-955a-6bf1ce525f1a', name: 'Республика Татарстан'},
    {guid: '026bc56f-3731-48e9-8245-655331f596c0', name: 'Республика Тыва'},
    {guid: '52618b9c-bcbb-47e7-8957-95c63f0b17cc', name: 'Удмуртская Республика'},
    {guid: '8d3f1d35-f0f4-41b5-b5b7-e7cadf3e7bd7', name: 'Республика Хакасия'},
    {guid: 'de67dc49-b9ba-48a3-a4cc-c2ebfeca6c5e', name: 'Чеченская Республика'},
    {guid: '878fc621-3708-46c7-a97f-5a13a4176b3e', name: 'Чувашская Республика'},
    {guid: '8276c6a1-1a86-4f0d-8920-aba34d4cc34a', name: 'Алтайский край'},
    {guid: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8', name: 'Краснодарский край'},
    {guid: 'db9c4f8b-b706-40e2-b2b4-d31b98dcd3d1', name: 'Красноярский край'},
    {guid: '43909681-d6e1-432d-b61f-ddac393cb5da', name: 'Приморский край'},
    {guid: '327a060b-878c-4fb4-8dc4-d5595871a3d8', name: 'Ставропольский край'},
    {guid: '7d468b39-1afa-41ec-8c4f-97a8603cb3d4', name: 'Хабаровский край'},
    {guid: '844a80d6-5e31-4017-b422-4d9c01e9942c', name: 'Амурская область'},
    {guid: '294277aa-e25d-428c-95ad-46719c4ddb44', name: 'Архангельская область'},
    {guid: '83009239-25cb-4561-af8e-7ee111b1cb73', name: 'Астраханская область'},
    {guid: '639efe9d-3fc8-4438-8e70-ec4f2321f2a7', name: 'Белгородская область'},
    {guid: 'f5807226-8be0-4ea8-91fc-39d053aec1e2', name: 'Брянская область'},
    {guid: 'b8837188-39ee-4ff9-bc91-fcc9ed451bb3', name: 'Владимирская область'},
    {guid: 'da051ec8-da2e-4a66-b542-473b8d221ab4', name: 'Волгоградская область'},
    {guid: 'ed36085a-b2f5-454f-b9a9-1c9a678ee618', name: 'Вологодская область'},
    {guid: 'b756fe6b-bbd3-44d5-9302-5bfcc740f46e', name: 'Воронежская область'},
    {guid: '0824434f-4098-4467-af72-d4f702fed335', name: 'Ивановская область'},
    {guid: '6466c988-7ce3-45e5-8b97-90ae16cb1249', name: 'Иркутская область'},
    {guid: '90c7181e-724f-41b3-b6c6-bd3ec7ae3f30', name: 'Калининградская область'},
    {guid: '18133adf-90c2-438e-88c4-62c41656de70', name: 'Калужская область'},
    {guid: 'd02f30fc-83bf-4c0f-ac2b-5729a866a207', name: 'Камчатский край'},
    {guid: '393aeccb-89ef-4a7e-ae42-08d5cebc2e30', name: 'Кемеровская область'},
    {guid: '0b940b96-103f-4248-850c-26b6c7296728', name: 'Кировская область'},
    {guid: '15784a67-8cea-425b-834a-6afe0e3ed61c', name: 'Костромская область'},
    {guid: '4a3d970f-520e-46b9-b16c-50d4ca7535a8', name: 'Курганская область'},
    {guid: 'ee594d5e-30a9-40dc-b9f2-0add1be44ba1', name: 'Курская область'},
    {guid: '6d1ebb35-70c6-4129-bd55-da3969658f5d', name: 'Ленинградская область'},
    {guid: '1490490e-49c5-421c-9572-5673ba5d80c8', name: 'Липецкая область'},
    {guid: '9c05e812-8679-4710-b8cb-5e8bd43cdf48', name: 'Магаданская область'},
    {guid: '29251dcf-00a1-4e34-98d4-5c47484a36d4', name: 'Московская область'},
    {guid: '1c727518-c96a-4f34-9ae6-fd510da3be03', name: 'Мурманская область'},
    {guid: '88cd27e2-6a8a-4421-9718-719a28a0a088', name: 'Нижегородская область'},
    {guid: 'e5a84b81-8ea1-49e3-b3c4-0528651be129', name: 'Новгородская область'},
    {guid: '1ac46b49-3209-4814-b7bf-a509ea1aecd9', name: 'Новосибирская область'},
    {guid: '05426864-466d-41a3-82c4-11e61cdc98ce', name: 'Омская область'},
    {guid: '8bcec9d6-05bc-4e53-b45c-ba0c6f3a5c44', name: 'Оренбургская область'},
    {guid: '5e465691-de23-4c4e-9f46-f35a125b5970', name: 'Орловская область'},
    {guid: 'c99e7924-0428-4107-a302-4fd7c0cca3ff', name: 'Пензенская область'},
    {guid: '4f8b1a21-e4bb-422f-9087-d3cbf4bebc14', name: 'Пермский край'},
    {guid: 'f6e148a1-c9d0-4141-a608-93e3bd95e6c4', name: 'Псковская область'},
    {guid: 'f10763dc-63e3-48db-83e1-9c566fe3092b', name: 'Ростовская область'},
    {guid: '963073ee-4dfc-48bd-9a70-d2dfc6bd1f31', name: 'Рязанская область'},
    {guid: 'df3d7359-afa9-4aaa-8ff9-197e73906b1c', name: 'Самарская область'},
    {guid: 'df594e0e-a935-4664-9d26-0bae13f904fe', name: 'Саратовская область'},
    {guid: 'aea6280f-4648-460f-b8be-c2bc18923191', name: 'Сахалинская область'},
    {guid: '92b30014-4d52-4e2e-892d-928142b924bf', name: 'Свердловская область'},
    {guid: 'e8502180-6d08-431b-83ea-c7038f0df905', name: 'Смоленская область'},
    {guid: 'a9a71961-9363-44ba-91b5-ddf0463aebc2', name: 'Тамбовская область'},
    {guid: '61723327-1c20-42fe-8dfa-402638d9b396', name: 'Тверская область'},
    {guid: '889b1f3a-98aa-40fc-9d3d-0f41192758ab', name: 'Томская область'},
    {guid: 'd028ec4f-f6da-4843-ada6-b68b3e0efa3d', name: 'Тульская область'},
    {guid: '54049357-326d-4b8f-b224-3c6dc25d6dd3', name: 'Тюменская область'},
    {guid: 'fee76045-fe22-43a4-ad58-ad99e903bd58', name: 'Ульяновская область'},
    {guid: '27eb7c10-a234-44da-a59c-8b1f864966de', name: 'Челябинская область'},
    {guid: 'b6ba5716-eb48-401b-8443-b197c9578734', name: 'Забайкальский край'},
    {guid: 'a84b2ef4-db03-474b-b552-6229e801ae9b', name: 'Ярославская область'},
    {guid: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5', name: 'Москва'},
    {guid: 'c2deb16a-0330-4f05-821f-1d09c93331e6', name: 'Санкт-Петербург'},
    {guid: '1b507b09-48c9-434f-bf6f-65066211c73e', name: 'Еврейская автономная область'},
    {guid: 'd66e5325-3a25-4d29-ba86-4ca351d9704b', name: 'Ханты-Мансийский автономный округ - Югра'},
    {guid: '89db3198-6803-4106-9463-cbf781eff0b8', name: 'Ненецкий автономный округ'},
    {guid: 'f136159b-404a-4f1f-8d8d-d169e1374d5c', name: 'Чукотский автономный округ'},
    {guid: '826fa834-3ee8-404f-bdbc-13a5221cfb6e', name: 'Ямало-Ненецкий автономный округ'},
    {guid: 'bd8e6511-e4b9-4841-90de-6bbc231a789e', name: 'Республика Крым'},
    {guid: '6fdecb78-893a-4e3f-a5ba-aa062459463b', name: 'г.Севастополь'},
    {guid: '63ed1a35-4be6-4564-a1ec-0c51f7383314', name: 'Байконур'}
  ];
}
