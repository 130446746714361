import * as React from 'react';
import {YMaps, Map, ObjectManager} from 'react-yandex-maps';
import axios from 'axios';
import {IUser} from '../../interfaces';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import {RATING_URL} from '../../config/urls';

const pin1 = require('../../assets/img/pin-org-1.svg');
const pin2 = require('../../assets/img/pin-org-2.svg');
const pin3 = require('../../assets/img/pin-org-3.svg');
const pin4 = require('../../assets/img/pin-org-4.svg');
const pin5 = require('../../assets/img/pin-org-5.svg');
const pin6 = require('../../assets/img/pin-org-6.svg');
const pin7 = require('../../assets/img/pin-org-7.svg');

const faceImg = [];
faceImg[1] = require('../../assets/img/face-1@2x.png');
faceImg[2] = require('../../assets/img/face-2@2x.png');
faceImg[3] = require('../../assets/img/face-3@2x.png');
faceImg[4] = require('../../assets/img/face-4@2x.png');
faceImg[5] = require('../../assets/img/face-5@2x.png');

const iconStar = require('../../assets/img/icon-star.svg');

export interface AppProps {
    authUser: IUser;
    filters?: any;
    updateFilters: string;
}

class TopMapComponent extends React.Component<AppProps, any> {
    private map: any;
    constructor(props) {
        super(props);
        this.state = {
            fullMap: false,
            placemarkOrg: {},
            parentPlacemarkOrg: {},
            lat: 55.753559,
            lon: 37.609218,
            clusterer: null,
            organizationsGeo: [],
            zoom: 7,
        };
        this.map = null;
        this.handleMapChange = this.handleMapChange.bind(this);
        this.handlePlacemarkClose = this.handlePlacemarkClose.bind(this);
    }

    componentDidMount() {
        this.getGeo();
    }

    componentWillReceiveProps() {
        this.getGeo();
    }

    handlePlacemarkClose() {
        this.setState((prevState) => {
            return {
                lat: prevState.placemarkOrg.geo.lat,
                lon: prevState.placemarkOrg.geo.lon,
                placemarkOrg: {},
            };
        }, () => {
            this.prepareClusterer(this.state.organizationsGeo);
        });
    }

    handlePlacemarkClick(orgIdx) {
        if (this.state.organizationsGeo[orgIdx]) {
            const org = this.state.organizationsGeo[orgIdx];
            axios.get('https://api.rating.we-change.ru/search/organizations/org/' + org._source.id).then((r) => {
                let po = r.data._source;
                po['lr'] = org.lr
                let ppo = {};
                if (po.parent_id > 0) {
                    axios.get('https://api.rating.we-change.ru/search/organizations/org/' + po.parent_id).then((r) => {
                        ppo = r.data._source;
                        this.setState({
                            fullMap: true,
                            placemarkOrg: po,
                            parentPlacemarkOrg: ppo
                        }, () => {
                            this.prepareClusterer(this.state.organizationsGeo);
                        });
                    });
                } else {
                    this.setState({
                        fullMap: true,
                        placemarkOrg: po,
                        parentPlacemarkOrg: {}
                    }, () => {
                        this.prepareClusterer(this.state.organizationsGeo);
                    });
                }
            });
        }
    }

    prepareClusterer(organizations) {
        const valPins = [pin1, pin2, pin4, pin5];
        const features = [];
        organizations.map((org, i) => {
            if (!(org._source.geo.lat === 0 && org._source.geo.lon === 0)) {
                let object = {
                    'type': 'Feature',
                    'id': this.state.placemarkOrg.id === org._source.id ? i + '_selected' : i,
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [org._source.geo.lat, org._source.geo.lon]
                    },
                    'options': {
                        iconLayout: 'default#image',
                        iconImageHref: this.state.placemarkOrg.id === org._source.id ? pin6 : (org.lr ? (org.lr > 65 ? pin5 : (org.lr <= 45 ? pin1 : pin3) ) : pin1),
                        iconImageSize: [22, 32]
                    },
                    'properties': {
                        'clusterCaption': org._source.title,
                    }
                };

                features.push(object);
            }
        });
        let clusterer = (<ObjectManager
            onClick={(e) => {
                this.handlePlacemarkClick(e.get('objectId'));
            }}
            options={{
                clusterize: true,
                gridSize: 128,
                groupByCoordinates: false,
                clusterDisableClickZoom: false,
                clusterHideIconOnBalloonOpen: false,
                geoObjectHideIconOnBalloonOpen: false,
            }}
            objects={{
                preset: 'islands#greenDotIcon',
            }}
            clusters={{
                preset: 'islands#invertedGrayClusterIcons',
            }}
            features={features}
        />);
        this.setState({clusterer});
    }

    getGeo() {
        const category = (this.props.filters && Number(this.props.filters.category) > 0) ? Number(this.props.filters.category) : null,
              userCategories = [],
              userOrganizations = [],
              sphere = (this.props.filters && Number(this.props.filters.sphere) > 0) ? Number(this.props.filters.sphere) : null,
              guids = (this.props.filters && this.props.filters.region !== '') ? [this.props.filters.region] : [],
              query = '';
        this.props.authUser.organizations.forEach(o => {
            userOrganizations.push(o.organization_id);
        });
        this.props.authUser.categories.forEach(c => {
            userCategories.push(c.category_id);
        });
        this.props.authUser.regions.forEach(r => {
            guids.push(r.region_fias);
        });
        const categoryFilter = category ? {'term': {'category_id': category}} : null,
              sphereFilter = sphere ? {'term': {'sphere_id': sphere}} : null,
              qFilter = query.length > 0 ? [{
                'multi_match': {
                    'type': 'most_fields',
                    'query': query,
                    'fields': ['title', 'address', 'parent_name']
                }
              }] : [];
        const filter = [],
              rFilter = [];
        if (guids.length > 0) {
            guids.forEach(guid => {
                rFilter.push({'match': {'region_fias': guid }});
            });
          // @ts-ignore
          qFilter.push(rFilter);
        }
        if (categoryFilter) {
            filter.push(categoryFilter);
        }
        if (sphereFilter && !categoryFilter) {
            filter.push(sphereFilter);
        }
        if (userCategories.length > 0) {
          // @ts-ignore
          qFilter.push({'terms': {'category_id': userCategories}});
        }
        if (userOrganizations.length > 0) {
            // @ts-ignore
            qFilter.push({'terms': {'id': userOrganizations}});
        }
        filter.push({
            'exists': {'field': 'geo'}
        });
        axios.post('https://api.rating.we-change.ru/search/organizations/_search',
            {
                'from': 0, 'size': 10000,
                '_source': [
                    'id',
                    'geo',
                    'rating',
                    'title',
                    'region_fias'
                ],
                'query': {
                    'bool': {
                        'must': qFilter,
                        // 'should': rFilter,
                        'filter': filter
                    }
                }
            }).then(r => {
                if (r.data.hits && r.data.hits.hits.length > 0) {
                    let organizationsGeo = r.data.hits.hits;
                    axios.get(RATING_URL + '/landing-stat/').then(res => {
                        organizationsGeo.forEach((bo, i) => {
                            organizationsGeo[i]['lr'] = res.data[bo._id] ? res.data[bo._id] : 0;
                        });
                        this.prepareClusterer(organizationsGeo);
                        this.setState({
                            organizationsGeo: organizationsGeo,
                            lat: organizationsGeo[0] ? organizationsGeo[0]._source.geo.lat : this.state.lat,
                            lon: organizationsGeo[0] ? organizationsGeo[0]._source.geo.lon : this.state.lon
                        });
                    }).catch((e) => {console.log(e)});
                } else {
                    this.setState({
                        organizationsGeo: [],
                        clusterer: null,
                    },
                );
            }
        }).catch((e) => {
            console.log(e);
        });
    }

    handleMapChange() {
        let lat = (this.map._bounds[0][0] + this.map._bounds[1][0]) / 2;
        let lng = (this.map._bounds[0][1] + this.map._bounds[1][1]) / 2;
        this.setState((prevState) => {
            return {
                fullMap: !prevState.fullMap,
                lat: lat,
                lon: lng,
            };
        });
    }

    render() {
        const defLat = this.state.lat,
              defLon = this.state.lon;
        return(
            <React.Fragment>
                <div className={ 'TopMapComponent ' + (this.state.fullMap ? 'TopMapComponent_expanded' : '') }>
                    <YMaps>
                        <Map
                            instanceRef={ el => { this.map = el; }}
                            state={{
                                center: ((this.state.placemarkOrg.geo && this.state.placemarkOrg.geo.lat) ? [this.state.placemarkOrg.geo.lat, this.state.placemarkOrg.geo.lon] : [defLat, defLon]),
                                zoom: this.state.zoom,
                                controls: []
                            }}
                            options={{suppressMapOpenBlock: true, yandexMapDisablePoiInteractivity: true}}
                            width='100%'
                            height={ this.state.fullMap ? '500px' : '350px' }
                        >
                            {this.state.clusterer}
                        </Map>
                    </YMaps>
                    <div className='organisation-list TopMapComponent__organisation-list'>
                        {(this.state.placemarkOrg.id > 0 ? (
                            <div className='organisation-list__card organisation-list__card_light'>
                                <button
                                    className='button button_round organisation-list__button organisation-list__button_close'
                                    onClick={ () => { this.handlePlacemarkClose(); }}>
                                </button>
                                <div className='info organisation-list__info'>
                                        <Link to={'/organizations/' + this.state.placemarkOrg.id}>
                                            <div className='info__name organisation-list__name'>
                                              {this.state.placemarkOrg.category.title}
                                            </div>
                                            <div className='comment organisation-list__comment'>
                                              <span style={{color: this.state.placemarkOrg.lr <= 40 ? '#FF0000' : (this.state.placemarkOrg.lr > 65 ? '#34bf01' : '#ec7a00')}}>{this.state.placemarkOrg.lr.toFixed(2)}</span>  {this.state.placemarkOrg.title}
                                            </div>
                                            <div className='info__name organisation-list__name'>
                                                {this.state.placemarkOrg.address}`
                                            </div>
                                        </Link>
                                  { /*<div className={'info info_rating-' + Math.round(this.state.placemarkOrg.rating)}>
                                            <div className='info__evaluation'>
                                                <img className='info__img' src={faceImg[Math.round(this.state.placemarkOrg.rating)]}/>
                                                <div className={this.state.placemarkOrg.rating > 0 ? 'info__count' : 'hide'}>{(this.state.placemarkOrg.rating).toFixed(2)}</div>
                                            </div>
                                        </div>*/ }
                                </div>
                            </div>
                        ) : '')}
                    </div>
                    <button
                        className='button button_bold button_expand'
                        onClick={ this.handleMapChange }
                    >
                        {!this.state.fullMap ? 'РАЗВЕРНУТЬ КАРТУ' : 'СВЕРНУТЬ КАРТУ'}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

export default connect<{}, {}, any>((state: any) => {
    return {
        authUser: state.app.authUser,
        loadingApp: state.app.loadingApp,
        filters: state.app.filters,
        updateFilters: state.app.updateFilters,
    };
})(TopMapComponent);
